import React, { useState, useEffect, useRef } from "react";
import {
  ArrowUpDown,
  Book,
  MoreVertical,
  UserCheck,
  UserX,
  Edit,
  PhoneCall,
  MessageCircle,
  HelpCircle,
  Clock,
  CheckCircle,
  Wrench,
  User,
  MapPin,
  Calendar,
  Mail,
  Phone,
  Users,
  XCircle,
  Star,
  TrendingUp,
  Brain,
  Award,
  Target,
  Zap,
  Briefcase,
  ChevronRight,
  ThumbsUp,
  ThumbsDown,
  FileText,
  AlertCircle,
  UserPlus,
  GraduationCap,
  X,
  Tag,
  MessageSquare,
  DownloadIcon,
} from "lucide-react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Legend,
} from "recharts";
import QuestionnaireResponses from "./QuestionnaireResponses";
import Modal from "react-modal";
import Interview from "./Interview";
import Checklist from "./Checklist";
import PersonalityType from "./PersonalityType";
import ReferenceList from "./components/ReferenceList";
import InterviewActionButtons from "./components/InterviewActionButtons";
import NoteAddForm from "./NoteForm";
import { api, API_BASE_URL } from "./utils/api";
import {
  hireJobseeker,
  rejectJobseeker,
  chaseJobseekerForQuestionnaire,
  capitalizeWords,
  calculatePercentile,
  addOrdinalSuffix,
  sortedJobseekers,
} from "./utils";
import { useNotification } from "./CustomNotificationSystem";
import MessageInterface from "./MessageInterface";
import { getNameInitials } from "./utils";
import { Tooltip as ToolTipHtml } from "react-tooltip";
import JobseekerStatusMultiSelect from "./components/JobseekerStatusMultiSelect";
import TagInterface from "./components/TagInterface";
import TagComponent from "./components/Tags";
import { useTag } from "./services/Tags";
import Select from "react-select";

// Placeholder function for API call
/*const fetchJobseekers = async (jobId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const filteredJobseekers = dummyJobseekers.filter(js => js.jobId === jobId);
      resolve(filteredJobseekers);
    }, 500);
  });
};*/

const ActionMenu = ({ jobseekerId, jobseekerName, jobseeker, onAction }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropupMode, setDropupMode] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    const updateDropupMode = () => {
      if (buttonRef.current) {
        const buttonRect = buttonRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const spaceBelow = windowHeight - buttonRect.bottom;
        // If space below is less than 320px (approximate menu height), switch to dropup
        setDropupMode(spaceBelow < 320);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", updateDropupMode);
    window.addEventListener("resize", updateDropupMode);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", updateDropupMode);
      window.removeEventListener("resize", updateDropupMode);
    };
  }, []);

  const handleAction = (action) => {
    onAction(action, jobseekerId, jobseekerName, jobseeker);
    setIsOpen(false);
  };

  const actions = [
    { name: "Add Notes", icon: <Edit size={16} className="mr-2" /> },
    {
      name: "Ask Further Questions",
      icon: <HelpCircle size={16} className="mr-2" />,
    },
    { name: "Call", icon: <PhoneCall size={16} className="mr-2" /> },
    {
      name: "Chase up Questionnaire",
      icon: <MessageCircle size={16} className="mr-2" />,
    },
    {
      name: "Tag",
      icon: <Tag size={16} className="mr-2" />,
    },
    {
      name: "Invite to Interview",
      icon: <UserCheck size={16} className="mr-2" />,
    },
    { name: "Mark as Hired", icon: <UserCheck size={16} className="mr-2" /> },
    { name: "Reject", icon: <UserX size={16} className="mr-2" /> },
    {
      name: "Message",
      icon: <MessageSquare size={16} className="mr-2" />,
    },
  ].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="relative" ref={menuRef}>
      <button
        ref={buttonRef}
        onClick={() => {
          const buttonRect = buttonRef.current.getBoundingClientRect();
          const windowHeight = window.innerHeight;
          const spaceBelow = windowHeight - buttonRect.bottom;
          setDropupMode(spaceBelow < 320);
          setIsOpen(!isOpen);
        }}
        className="p-2 rounded-full hover:bg-gray-200 focus:outline-none"
      >
        <MoreVertical size={24} strokeWidth={2.5} />
      </button>
      {isOpen && (
        <div
          className={`absolute ${dropupMode ? "bottom-full mb-2" : "top-full mt-2"
            } right-0 w-48 bg-white rounded-md shadow-lg z-50`}
        >
          <div className="py-1">
            {actions.map(({ name, icon }) => (
              <button
                key={name}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                onClick={() => handleAction(name)}
              >
                {icon} {name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const StatsTab = ({ jobseeker, jobseekersAverageScore, initialJobseekers }) => {
  // Dummy data for comparisons
  let radarData = [];
  const summary_scoring = jobseeker?.detailedInfo?.summary_scoring
    ? jobseeker?.detailedInfo?.summary_scoring
    : {};
  let average_overall_score = 0;
  for (let data_point in jobseekersAverageScore) {
    average_overall_score += parseInt(jobseekersAverageScore[data_point]);
    radarData.push({
      subject: capitalizeWords(data_point.replace("_", " ")),
      A: summary_scoring[data_point] ? summary_scoring[data_point] : 0,
      B: jobseekersAverageScore[data_point]
        ? jobseekersAverageScore[data_point]
        : 0,
    });
  }

  const total_average_count = Object.keys(jobseekersAverageScore).length;

  if (total_average_count > 0)
    average_overall_score = average_overall_score / total_average_count;

  const barData = [
    {
      name: "Overall Score",
      candidate: jobseeker.score,
      average: average_overall_score,
    },
    {
      name: "Technical Score",
      candidate: summary_scoring.technical_knowledge,
      average: jobseekersAverageScore.technical_knowledge,
    },
    {
      name: "Soft Skills",
      candidate: summary_scoring.soft_skills,
      average: jobseekersAverageScore.soft_skills,
    },
  ];

  let main_percentile_data = [];
  main_percentile_data["overall_score"] = [];

  for (let index in initialJobseekers) {
    if (initialJobseekers[index].score) {
      main_percentile_data["overall_score"].push(
        initialJobseekers[index].score,
      );
    }
    if (!initialJobseekers[index].detailedInfo?.summary_scoring) continue;

    let scores = initialJobseekers[index].detailedInfo?.summary_scoring;
    for (let data_point in scores) {
      if (main_percentile_data[data_point]) {
        main_percentile_data[data_point].push(scores[data_point]);
      } else {
        main_percentile_data[data_point] = [scores[data_point]];
      }
    }
  }

  let percentileData = [];
  let jobseeker_score = 0;
  for (let data_point in main_percentile_data) {
    if (summary_scoring[data_point]) {
      jobseeker_score = summary_scoring[data_point];
    } else if (data_point === "overall_score") {
      jobseeker_score = jobseeker.score;
    } else {
      continue;
    }

    const percentile = calculatePercentile(
      main_percentile_data[data_point],
      jobseeker_score,
    );

    percentileData.push({
      name: capitalizeWords(data_point.replace("_", " ")),
      percentile: percentile,
    });
  }

  /*
  const percentileData = [
    { name: "Overall Score", percentile: 85 },
    { name: "Technical Knowledge", percentile: 92 },
    { name: "Safety Awareness", percentile: 98 },
    { name: "Problem Solving", percentile: 78 },
    { name: "Communication", percentile: 84 },
    { name: "Company Fit", percentile: 76 },
  ];
  */

  return (
    <div className="p-4 space-y-8 bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg">
      <h3 className="text-2xl font-bold mb-6 text-indigo-800 flex items-center">
        <TrendingUp className="mr-2" /> Candidate Statistics
      </h3>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h4 className="text-lg font-semibold mb-4 text-indigo-800 flex items-center">
            <Target className="mr-2" /> Skills Comparison
          </h4>
          <div style={{ width: "100%", height: 300 }}>
            <ResponsiveContainer>
              <RadarChart data={radarData}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis angle={30} domain={[0, 100]} />
                <Radar
                  name={jobseeker.name}
                  dataKey="A"
                  stroke="#8884d8"
                  fill="#8884d8"
                  fillOpacity={0.6}
                />
                <Radar
                  name="Average Applicant"
                  dataKey="B"
                  stroke="#82ca9d"
                  fill="#82ca9d"
                  fillOpacity={0.6}
                />
                <Legend />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h4 className="text-lg font-semibold mb-4 text-indigo-800 flex items-center">
            <Award className="mr-2" /> Score Comparison
          </h4>
          <div style={{ width: "100%", height: 300 }}>
            <ResponsiveContainer>
              <BarChart data={barData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis domain={[0, 100]} />
                <Tooltip />
                <Legend />
                <Bar dataKey="candidate" name={jobseeker.name} fill="#8884d8" />
                <Bar
                  dataKey="average"
                  name="Average Applicant"
                  fill="#82ca9d"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <h4 className="text-lg font-semibold mb-4 text-indigo-800 flex items-center">
          <TrendingUp className="mr-2" /> Percentile Rankings
        </h4>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {percentileData.map((item, index) => (
            <div key={index} className="bg-gray-50 p-4 rounded-lg">
              <h5 className="font-semibold text-gray-700">{item.name}</h5>
              <div className="flex items-center mt-2">
                <ChevronRight className="text-green-500 mr-2" />
                <span className="text-2xl font-bold text-indigo-600">
                  {addOrdinalSuffix(Math.ceil(item.percentile))}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const InsightCard = ({ title, icon, content }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <h4 className="text-lg font-semibold mb-3 text-indigo-800 flex items-center">
      {icon}
      <span className="ml-2">{title}</span>
    </h4>
    <div className="text-gray-700">
      {typeof content === "string" ? <p>{content}</p> : content}
    </div>
  </div>
);

const ExpandedJobseekerView = ({
  jobseeker,
  selectedJob,
  jobseekersAverageScore,
  initialJobseekers,
  companyUser,
}) => {
  const [activeTab, setActiveTab] = useState("summary");

  const technical_knowledge =
    jobseeker.detailedInfo.summary_scoring &&
      jobseeker.detailedInfo.summary_scoring.technical_knowledge
      ? jobseeker.detailedInfo.summary_scoring.technical_knowledge
      : 0;
  const safety_awareness =
    jobseeker.detailedInfo.summary_scoring &&
      jobseeker.detailedInfo.summary_scoring.safety_awareness
      ? jobseeker.detailedInfo.summary_scoring.safety_awareness
      : 0;
  const problem_solving =
    jobseeker.detailedInfo.summary_scoring &&
      jobseeker.detailedInfo.summary_scoring.problem_solving
      ? jobseeker.detailedInfo.summary_scoring.problem_solving
      : 0;
  const communication =
    jobseeker.detailedInfo.summary_scoring &&
      jobseeker.detailedInfo.summary_scoring.communication
      ? jobseeker.detailedInfo.summary_scoring.communication
      : 0;
  const company_fit =
    jobseeker.detailedInfo.summary_scoring &&
      jobseeker.detailedInfo.summary_scoring.company_fit
      ? jobseeker.detailedInfo.summary_scoring.company_fit
      : 0;

  const scoreData = [
    {
      name: "Technical Knowledge",
      score: technical_knowledge,
      fill: "#4299e1",
    },
    { name: "Safety Awareness", score: safety_awareness, fill: "#48bb78" },
    { name: "Problem Solving", score: problem_solving, fill: "#ed8936" },
    { name: "Communication", score: communication, fill: "#9f7aea" },
    { name: "Company Fit", score: company_fit, fill: "#f56565" },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const isExpired = (dateString) => {
    const expiryDate = new Date(dateString);
    const today = new Date();
    return expiryDate < today;
  };

  const renderAIInsights = (jobseeker) =>
    jobseeker.detailedInfo.assessment_insights && (
      <div className="p-4 space-y-6 bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg">
        <h3 className="text-2xl font-bold mb-6 text-indigo-800 flex items-center">
          <Brain className="mr-2" /> Insights
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {jobseeker.detailedInfo.assessment_insights?.overall_assessment && (
            <InsightCard
              title="Overall Assessment"
              icon={<Target className="text-blue-500" />}
              content={
                jobseeker.detailedInfo.assessment_insights.overall_assessment
              }
            />
          )}

          {jobseeker.detailedInfo.assessment_insights?.strengths_weaknesses && (
            <InsightCard
              title="Key Strengths and Weaknesses"
              icon={<TrendingUp className="text-green-500" />}
              content={
                <div className="space-y-2">
                  <div>
                    <h5 className="font-semibold text-green-600 flex items-center">
                      <CheckCircle size={16} className="mr-1" /> Strengths
                    </h5>
                    <ul className="list-disc pl-5 text-gray-700">
                      {jobseeker.detailedInfo.assessment_insights.strengths_weaknesses.strengths.map(
                        (strengths) => (
                          <li>{strengths}</li>
                        ),
                      )}
                    </ul>
                  </div>
                  <div>
                    <h5 className="font-semibold text-red-600 flex items-center">
                      <XCircle size={16} className="mr-1" /> Areas for
                      Improvement
                    </h5>
                    <ul className="list-disc pl-5 text-gray-700">
                      {jobseeker.detailedInfo.assessment_insights.strengths_weaknesses.weaknesses.map(
                        (weaknesses) => (
                          <li>{weaknesses}</li>
                        ),
                      )}
                    </ul>
                  </div>
                </div>
              }
            />
          )}

          {jobseeker.detailedInfo.assessment_insights?.personality_traits && (
            <InsightCard
              title="Personality Traits"
              icon={<Users className="text-purple-500" />}
              content={
                jobseeker.detailedInfo.assessment_insights.personality_traits
              }
            />
          )}

          {jobseeker.detailedInfo.assessment_insights
            ?.technical_skills_analysis && (
              <InsightCard
                title="Technical Skills Analysis"
                icon={<Zap className="text-yellow-500" />}
                content={
                  jobseeker.detailedInfo.assessment_insights
                    .technical_skills_analysis
                }
              />
            )}

          {jobseeker.detailedInfo.assessment_insights?.cultural_fit && (
            <InsightCard
              title="Cultural Fit Assessment"
              icon={<Briefcase className="text-indigo-500" />}
              content={jobseeker.detailedInfo.assessment_insights.cultural_fit}
            />
          )}

          {jobseeker.detailedInfo.assessment_insights
            ?.learning_potential_adaptability && (
              <InsightCard
                title="Learning Potential and Adaptability"
                icon={<TrendingUp className="text-green-500" />}
                content={
                  jobseeker.detailedInfo.assessment_insights
                    .learning_potential_adaptability
                }
              />
            )}

          {jobseeker.detailedInfo.assessment_insights?.leadership_potential && (
            <InsightCard
              title="Leadership Potential"
              icon={<Award className="text-orange-500" />}
              content={
                jobseeker.detailedInfo.assessment_insights.leadership_potential
              }
            />
          )}
        </div>

        {jobseeker.detailedInfo.assessment_insights?.recommendations && (
          <div className="mt-8 bg-white p-6 rounded-lg shadow-md">
            <h4 className="text-xl font-semibold mb-4 text-indigo-800 flex items-center">
              <Star className="mr-2" /> Recommendations
            </h4>
            <ul className="space-y-2">
              {jobseeker.detailedInfo.assessment_insights.recommendations.map(
                (recommendation, index) => (
                  <li key={index} className="flex items-start">
                    <ChevronRight className="text-green-500 mt-1 mr-2 flex-shrink-0" />
                    <span>{recommendation}</span>
                  </li>
                ),
              )}
            </ul>
          </div>
        )}
      </div>
    );

  const renderChecklist = () => (
    <Checklist jobId={selectedJob} jobseeker={jobseeker} />
  );

  const renderInterview = () => <Interview />;

  const renderReference = () => <ReferenceList candidateId={jobseeker._id} />;
  const renderMessages = () => (
    <MessageInterface
      worker={[{ id: jobseeker._id, name: jobseeker.name }]}
      companyUser={companyUser}
      initialJobseekers={initialJobseekers}
      canSelectOtherJobseekers={false}
    />
  );

  const renderPersonalityTest = () => {
    // This is dummy data. In a real application, you would fetch this data based on the jobseeker's personality type.
    const dummyPersonalityData = {
      type: "ENFJ",
      name: "Extraverted Intuitive Feeling Judging",
      nameDescription: "Extraverted Feeling with Introverted Intuition",
      epithet: "The Giver",
      description: `As an ENFJ, your primary mode of living is focused externally, where you deal with things according to how you feel about them, or how they fit into your personal value system. Your secondary mode is internal, where you take things in primarily via your intuition...`,
      jungianFunctionalPreference: {
        dominant: "Extraverted Feeling",
        auxiliary: "Introverted Intuition",
        tertiary: "Extraverted Sensing",
        inferior: "Introverted Thinking",
      },
      generalTraits: [
        "Genuinely and warmly interested in people",
        "Value people's feelings",
        "Value structure and organization",
        // ... (other traits)
      ],
      relationshipStrengths: [
        "Good verbal communication skills",
        "Very perceptive about people's thoughts and motives",
        // ... (other strengths)
      ],
      relationshipWeaknesses: [
        "Tendency to be smothering and over-protective",
        "Tendency to be controlling and/or manipulative",
        // ... (other weaknesses)
      ],
      successDefinition: `ENFJs are motivated by external human situations, primarily by other people; their talents, their needs, their aspirations and their cares forming the world in which an ENFJ lives...`,
      strengths: [
        "Making others feel valued and important",
        "Quickly seeing the positive and negative aspects of a human situation",
        // ... (other strengths)
      ],
      gifts: [
        "Understanding and empathizing with the feelings of others; realizing 'where they are coming from'.",
        "A talent for creative expression which can turn ordinary things and situations into something magical.",
        // ... (other gifts)
      ],
      potentialProblemAreas: `With any gift of strength, there is an associated weakness. Without "bad", there would be no "good". Without "difficult", there would be no "easy"...`,
      explanationOfProblems: `Most of the problems described above can be seen as a direct result of a too dominant Extraverted Thinking function ruling the personality...`,
      solutions: `To grow as an individual, the ENTJ needs to recognize the role Intuition plays in their life, and learn to understand its language...`,
      livingHappilyTips: `Some ENTJ's have difficulty fitting into our society. Their problems are generally associated with a strongly dogmatic and overly rational approach to life...`,
      tenRulesToLive: [
        "Feed Your Strengths! Give yourself every opportunity to show others your appreciation of a situation and how you could see it through to a good outcome. Take charge where you can make it count.",
        "Face Your Weaknesses! Understand you have limits too. Your careful world view is not the whole deal. How things look and feel may not concern you, but they concern many others. Try and allow such things to be and learn from them.",
        // ... (other rules)
      ],
    };

    return (
      <div className="p-4 space-y-6 bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg">
        <h3 className="text-2xl font-bold mb-6 text-indigo-800 flex items-center">
          <User className="mr-2" /> Personality Type
        </h3>
        <PersonalityType personalityType={dummyPersonalityData} />
      </div>
    );
  };

  const renderOnboarding = () => (
    <div className="p-4 space-y-6 bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg">
      <h3 className="text-2xl font-bold mb-6 text-indigo-800 flex items-center">
        <UserPlus className="mr-2" /> Onboarding
      </h3>
      <p>Onboarding information and progress will be shown here.</p>
    </div>
  );

  const renderTraining = () => (
    <div className="p-4 space-y-6 bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg">
      <h3 className="text-2xl font-bold mb-6 text-indigo-800 flex items-center">
        <GraduationCap className="mr-2" /> Training
      </h3>
      <p>Training details and progress will be displayed here.</p>
    </div>
  );

  const renderDocumentation = () => {
    const { detailedInfo } = jobseeker;
    if (!detailedInfo) return null;

    const isImage = (fileType) => fileType === "image";
    let documents = detailedInfo?.documentation?.filter(
      (doc) => !isImage(doc.fileType),
    );
    let images = detailedInfo.documentation?.filter((doc) =>
      isImage(doc.fileType),
    );

    if (!documents) {
      documents = [];
    }
    if (!images) {
      images = [];
    }

    return (
      <div className="p-4 space-y-6 bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg">
        <h3 className="text-2xl font-bold mb-6 text-indigo-800 flex items-center">
          <FileText className="mr-2" /> Documentation and Certifications
        </h3>

        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h4 className="text-lg font-semibold mb-4 text-indigo-800 flex items-center">
            <FileText className="mr-2 text-blue-500" /> Documentation
          </h4>
          <div className="grid grid-cols-1 gap-4">
            <div className="p-4 rounded-lg">
              <ul className="space-y-2">
                {documents.map((doc, index) => (
                  <li key={index} className="flex items-center">
                    <FileText className="mr-2" />
                    <a
                      href={API_BASE_URL + doc.file}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline flex items-center"
                    >
                      {doc.fileTitleDisplay ?? doc.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {images.length > 0 && (
              <div className="p-4 rounded-lg">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {images.map((doc, index) => (
                    <div key={index} className="p-4 rounded-lg">
                      <h5 className="font-medium mb-2">{doc.name}</h5>
                      <img
                        src={doc.file}
                        alt={doc.name}
                        className="w-full h-32 object-cover rounded"
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h4 className="text-lg font-semibold mb-4 text-indigo-800 flex items-center">
            <Award className="mr-2 text-blue-500" /> Certifications
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {detailedInfo?.certifications?.map((cert, index) => (
              <div key={index} className="p-4 rounded-lg">
                <h5 className="font-medium mb-2">
                  {cert.fileTitleDisplay ?? cert.name}
                </h5>
                {cert.expiryDate && (
                  <p
                    className={`text-sm ${isExpired(cert.expiryDate) ? "text-red-600 font-bold" : "text-gray-600"}`}
                  >
                    {isExpired(cert.expiryDate) && (
                      <AlertCircle className="inline mr-1" size={16} />
                    )}
                    Expiry Date: {formatDate(cert.expiryDate)}
                  </p>
                )}
                {cert.file &&
                  cert.file.trim() !== "" &&
                  (isImage(cert.fileType) ? (
                    <img
                      src={cert.file}
                      alt={cert.name}
                      className="w-full h-32 object-cover rounded mt-2"
                    />
                  ) : (
                    <a
                      href={API_BASE_URL + cert.file}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline flex items-center mt-2"
                    >
                      <FileText className="mr-2" />
                      View Certificate
                    </a>
                  ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderSummary = () => (
    <div className="p-4 space-y-6 bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg">
      <h3 className="text-2xl font-bold mb-6 text-indigo-800 flex items-center">
        <TrendingUp className="mr-2" /> Candidate Summary
      </h3>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
          <CheckCircle className="text-green-500 mr-4" size={24} />
          <div>
            <div className="font-semibold text-gray-600">
              Questionnaire Completion
            </div>
            <div className="text-2xl font-bold text-indigo-600">
              {jobseeker.detailedInfo.questionnaireResponses.length > 0
                ? 100
                : 0}
              %
            </div>
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
          <Clock className="text-blue-500 mr-4" size={24} />
          <div>
            <div className="font-semibold text-gray-600">Time Taken</div>
            <div className="text-2xl font-bold text-indigo-600">
              {jobseeker.detailedInfo.questionnaireDuration
                ? (
                  parseInt(jobseeker.detailedInfo.questionnaireDuration) /
                  1000 /
                  60
                ).toFixed(1)
                : " - "}{" "}
              min
            </div>
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
          <Wrench className="text-orange-500 mr-4" size={24} />
          <div>
            <div className="font-semibold text-gray-600">Technical Score</div>
            <div className="text-2xl font-bold text-indigo-600">
              {jobseeker.detailedInfo.summary_scoring &&
                jobseeker.detailedInfo.summary_scoring.technical_knowledge
                ? jobseeker.detailedInfo.summary_scoring.technical_knowledge
                : "00"}
              /100
            </div>
          </div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow-md flex items-center">
          <Users className="text-purple-500 mr-4" size={24} />
          <div>
            <div className="font-semibold text-gray-600">Soft Skills Score</div>
            <div className="text-2xl font-bold text-indigo-600">
              {jobseeker.detailedInfo.summary_scoring &&
                jobseeker.detailedInfo.summary_scoring.soft_skills
                ? jobseeker.detailedInfo.summary_scoring.soft_skills
                : "00"}
              /100
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <h4 className="text-lg font-semibold mb-4 text-indigo-800 flex items-center">
          <TrendingUp className="mr-2" /> Score Breakdown
        </h4>
        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer>
            <BarChart data={scoreData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis domain={[0, 100]} />
              <Tooltip />
              <Bar dataKey="score">
                {scoreData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.fill} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {jobseeker.detailedInfo.assessment_insights?.strengths_weaknesses && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h4 className="text-lg font-semibold mb-4 text-indigo-800 flex items-center">
              <ThumbsUp className="mr-2 text-green-500" /> Strengths
            </h4>
            <ul className="list-disc pl-5 space-y-2">
              {jobseeker.detailedInfo.assessment_insights.strengths_weaknesses.strengths.map(
                (strengths) => (
                  <li>{strengths}</li>
                ),
              )}
            </ul>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h4 className="text-lg font-semibold mb-4 text-indigo-800 flex items-center">
              <ThumbsDown className="mr-2 text-red-500" /> Areas for Improvement
            </h4>
            <ul className="list-disc pl-5 space-y-2">
              {jobseeker.detailedInfo.assessment_insights.strengths_weaknesses.weaknesses.map(
                (weaknesses) => (
                  <li>{weaknesses}</li>
                ),
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );

  const renderDetailedInfo = () => {
    const { detailedInfo } = jobseeker;
    if (!detailedInfo) return null;

    return (
      <div className="p-4 space-y-6 bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg">
        <div className="flex justify-between items-center w-full">
          <h3 className="text-2xl font-bold mb-6 text-indigo-800 flex items-center">
            <Book className="mr-2" /> Profile Information
          </h3>
          <a
            className="flex items-center text-indigo-800"
            target="_blank"
            href={`${API_BASE_URL}api/jobseekers/d/${jobseeker._id}`}
          >
            <DownloadIcon className="mr-1" /> Download{" "}
            {capitalizeWords(jobseeker.name)}'s Files
          </a>
        </div>
        {/* Personal Information Card */}
        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h4 className="text-lg font-semibold mb-4 text-indigo-800 flex items-center">
            <User className="mr-2 text-blue-500" /> Personal Information
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex items-center">
              <MapPin className="mr-2 text-gray-500" size={18} />
              <span>{jobseeker.location}</span>
            </div>
            <div className="flex items-center">
              <Calendar className="mr-2 text-gray-500" size={18} />
              <span>
                Applied: {new Date(jobseeker.appliedDate).toLocaleDateString()}
              </span>
            </div>
            <div className="flex items-center">
              <Mail className="mr-2 text-gray-500" size={18} />
              <a
                href={`mailto:${jobseeker.email}`}
                className="text-blue-600 hover:underline"
              >
                {jobseeker.email}
              </a>
            </div>
            <div className="flex items-center">
              <Phone className="mr-2 text-gray-500" size={18} />
              <a
                href={`tel:${jobseeker.phone}`}
                className="text-blue-600 hover:underline"
              >
                {jobseeker.phone}
              </a>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h4 className="text-lg font-semibold mb-4 text-indigo-800 flex items-center">
              <Book className="mr-2 text-blue-500" /> Education
            </h4>
            <ul className="space-y-2">
              {detailedInfo?.education?.map((edu, index) => (
                <li key={index} className="flex items-start">
                  <ChevronRight className="text-green-500 mt-1 mr-2 flex-shrink-0" />
                  <div>
                    <p className="font-medium">{edu.degree}</p>
                    <p className="text-sm text-gray-600">
                      {edu.institution} ({edu.year})
                    </p>
                  </div>
                </li>
              ))}
            </ul>

            {detailedInfo?.education?.length === 0 ? "None provided" : ""}
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h4 className="text-lg font-semibold mb-4 text-indigo-800 flex items-center">
              <Briefcase className="mr-2 text-purple-500" /> Work Experience
            </h4>
            {detailedInfo?.workExperience?.map((exp, index) => (
              <div key={index} className="mb-4">
                <p className="font-medium">
                  {exp.position} at {exp.company}
                </p>
                <p className="text-sm text-gray-600 mb-2">{exp.duration}</p>
                <ul className="list-disc pl-5 text-sm">
                  {exp.responsibilities.map((resp, idx) => (
                    <li key={idx} className="text-gray-700">
                      {resp}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            {detailedInfo?.workExperience?.length === 0 ? "None provided" : ""}
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h4 className="text-lg font-semibold mb-4 text-indigo-800 flex items-center">
              <Wrench className="mr-2 text-yellow-500" /> Skills
            </h4>
            <ul className="grid grid-cols-2 gap-2">
              {detailedInfo?.skills?.map((skill, index) => (
                <li key={index} className="flex items-center">
                  <ChevronRight className="text-green-500 mr-2 flex-shrink-0" />
                  <span>{capitalizeWords(skill)}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h4 className="text-lg font-semibold mb-4 text-indigo-800 flex items-center">
              <Award className="mr-2 text-red-500" /> Certifications
            </h4>
            <ul className="space-y-2 grid grid-row-1 gap-3">
              {detailedInfo?.certifications?.map((cert, index) => (
                <li key={index} className="flex items-start">
                  <ChevronRight className="text-green-500 mt-1 mr-2 flex-shrink-0" />
                  <div>
                    <p className="font-medium">
                      {cert.fileTitleDisplay ?? cert.name}
                    </p>
                    {cert.expiryDate && (
                      <p className="text-sm text-gray-600">
                        Expiry Date:{" "}
                        {new Date(cert.expiryDate).toLocaleDateString()}
                      </p>
                    )}
                    {cert.file &&
                      (cert.file.match(/\.(jpeg|jpg|gif|png)$/) ? (
                        <img
                          src={API_BASE_URL + cert.file}
                          alt={`${cert.name} Certificate`}
                          className="h-32 mt-2"
                        />
                      ) : (
                        <a
                          href={API_BASE_URL + cert.file}
                          className="text-blue-600 hover:underline mt-2 block"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Certificate
                        </a>
                      ))}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-4 bg-gray-50">
      <div className="flex items-center mb-4">
        <div className="ml-auto text-right"></div>
      </div>

      <div className="flex flex-wrap mb-4">
        <button
          className={`mr-2 mb-2 px-4 py-2 rounded ${activeTab === "summary" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => setActiveTab("summary")}
        >
          Summary
        </button>
        <button
          className={`mr-2 mb-2 px-4 py-2 rounded ${activeTab === "profile" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => setActiveTab("profile")}
        >
          Profile
        </button>
        <button
          className={`mr-2 mb-2 px-4 py-2 rounded ${activeTab === "documentation" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => setActiveTab("documentation")}
        >
          Documentation
        </button>
        <button
          className={`mr-2 mb-2 px-4 py-2 rounded ${activeTab === "questionnaire" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => setActiveTab("questionnaire")}
        >
          Questionnaire
        </button>
        <button
          className={`mr-2 mb-2 px-4 py-2 rounded ${activeTab === "ai-report" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => setActiveTab("ai-report")}
        >
          Insights
        </button>
        <button
          className={`mr-2 mb-2 px-4 py-2 rounded ${activeTab === "stats" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => setActiveTab("stats")}
        >
          Stats
        </button>
        {/*
        <button
          className={`mr-2 mb-2 px-4 py-2 rounded ${activeTab === "interview" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => setActiveTab("interview")}
        >
          Interview
        </button>
        <button
          className={`mr-2 mb-2 px-4 py-2 rounded ${activeTab === "personality" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => setActiveTab("personality")}
        >
          Personality
        </button>
        */}
        <button
          className={`mr-2 mb-2 px-4 py-2 rounded ${activeTab === "checklist" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => setActiveTab("checklist")}
        >
          Checklist
        </button>
        <button
          className={`mr-2 mb-2 px-4 py-2 rounded ${activeTab === "reference" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => setActiveTab("reference")}
        >
          Reference
        </button>
        <button
          className={`mr-2 mb-2 px-4 py-2 rounded ${activeTab === "messages" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => setActiveTab("messages")}
        >
          Messages
        </button>
        {/*
        <button
          className={`mr-2 mb-2 px-4 py-2 rounded ${activeTab === "onboarding" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => setActiveTab("onboarding")}
        >
          Onboarding
        </button>
        <button
          className={`mr-2 mb-2 px-4 py-2 rounded ${activeTab === "training" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
          onClick={() => setActiveTab("training")}
        >
          Training
        </button>
        */}
        <div className="flex flex-col ml-auto">
          <div className="text-3xl font-bold text-blue-600">
            {jobseeker.score ?? "00"}/100
          </div>
          <p className="text-sm text-gray-500">Overall Score</p>
        </div>
      </div>

      {activeTab === "summary" && renderSummary()}
      {activeTab === "profile" && renderDetailedInfo()}
      {activeTab === "documentation" && renderDocumentation()}
      {activeTab === "questionnaire" && (
        <QuestionnaireResponses jobseeker={jobseeker} />
      )}
      {activeTab === "ai-report" && renderAIInsights(jobseeker)}
      {activeTab === "stats" && (
        <StatsTab
          jobseekersAverageScore={jobseekersAverageScore}
          jobseeker={jobseeker}
          initialJobseekers={initialJobseekers}
        />
      )}
      {activeTab === "checklist" && renderChecklist()}
      {activeTab === "reference" && renderReference()}
      {activeTab === "messages" && renderMessages()}
      {activeTab === "interview" && renderInterview()}
      {activeTab === "personality" && renderPersonalityTest()}
      {activeTab === "onboarding" && renderOnboarding()}
      {activeTab === "training" && renderTraining()}
    </div>
  );
};

const JobseekerList = ({
  selectedJob,
  expandedJobseekerId,
  initialJobseekers,
  jobs,
  currentUser,
  currentCompany,
}) => {
  const [jobseekers, setJobseekers] = useState(initialJobseekers || []);
  const [expandedJobseeker, setExpandedJobseeker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedJobseekers, setSelectedJobseekers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [filterStatus, setFilterStatus] = useState(["all"]);
  const [filterQuestionnaireStatus, setFilterQuestionnaireStatus] = useState([
    "all",
  ]);
  const handleCloseModal = () => {
    setModalState({ isOpen: false, type: null, jobSeekerId: null });
  };

  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null,
    //jobSeekerId: null,
  });
  const [interviewData, setInterviewData] = useState({});
  const [jobseekerAverageScore, setJobseekerAverageScore] = useState([]);
  const notify = useNotification();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [sortName, setSortName] = useState(false);
  const [sortApplied, setSortApplied] = useState(false);
  const [sortQStatus, setSortQStatus] = useState(false);
  const [sortScore, setSortScore] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");
  const [tags, setTags] = useState([]);
  const [filterTags, setFilterTags] = useState(["all"]);
  const { deleteJobseekerTag, getTagsByCompany } = useTag();

  useEffect(() => {
    const jobseekers = sortedJobseekers(initialJobseekers);
    setJobseekers(jobseekers || []);
    setIsLoading(false);

    const getJobseekerAverageScore = () => {
      let average_score = [];
      let total_count = 0;
      for (let jobseeker of initialJobseekers) {
        if (jobseeker.detailedInfo?.summary_scoring) {
          const summary_scoring = jobseeker?.detailedInfo?.summary_scoring;
          total_count++;
          for (let data_point in summary_scoring) {
            if (average_score[data_point]) {
              average_score[data_point] += parseInt(
                summary_scoring[data_point],
              );
            } else {
              average_score[data_point] = parseInt(summary_scoring[data_point]);
            }
          }
        }
      }

      let average = {};
      for (let data_point in average_score) {
        average[data_point] = (
          parseInt(average_score[data_point]) / total_count
        ).toFixed(1);
      }
      setJobseekerAverageScore(average);
    };

    const getCompanyTags = async () => {
      const companyTags = await getTagsByCompany(currentCompany._id);
      setTags(companyTags.map((tag) => ({ label: tag.name, value: tag.name })));
    };

    getCompanyTags();
    getJobseekerAverageScore();
  }, [selectedJob, initialJobseekers, currentCompany]);

  useEffect(() => {
    if (expandedJobseekerId) {
      setExpandedJobseeker(expandedJobseekerId);
      const jobseekerElement = document.getElementById(
        `jobseeker-${expandedJobseekerId}`,
      );
      if (jobseekerElement) {
        jobseekerElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [expandedJobseekerId]);

  useEffect(() => {
    if (selectAll) {
      setSelectedJobseekers(
        jobseekers.map((js) => {
          return { id: js._id, name: js.name };
        }),
      );
    } else {
      setSelectedJobseekers([]);
    }
  }, [selectAll, jobseekers]);

  useEffect(() => {
    const name = jobs.filter((job) => {
      if (job._id === selectedJob) {
        return true;
      }
      return false;
    });
    setInterviewData({
      id: selectedJob,
      name: name[0]?.jobReference,
      candidates: selectedJobseekers,
    });
  }, [selectedJobseekers, selectedJob, jobs]);

  const getStatusColor = (status) => {
    if (!status) return "";
    switch (status.toLowerCase()) {
      case "not_started":
        return "bg-gray-200 text-gray-800";
      case "in_progress":
        return "bg-yellow-200 text-yellow-800";
      case "completed":
        return "bg-green-200 text-green-800";
      default:
        return "bg-gray-200 text-gray-800";
    }
  };

  const getScoreColor = (score) => {
    if (score >= 80) return "border-green-500 text-green-700";
    if (score >= 60) return "border-yellow-500 text-yellow-700";
    return "border-red-500 text-red-700";
  };

  const getApplicationStatusColor = (status) => {
    if (!status) return "";
    switch (status.toLowerCase()) {
      case "applied":
        return "bg-blue-200 text-blue-800";
      case "interview":
        return "bg-purple-200 text-purple-800";
      case "hired":
        return "bg-green-200 text-green-800";
      case "rejected":
        return "bg-red-200 text-red-800";
      default:
        return "bg-gray-200 text-gray-800";
    }
  };

  const handleCheckboxChange = (jobseekerId, jobseekerName, jobseeker) => {
    setSelectedJobseekers((prev) => {
      // Check if the jobseeker is already in the array
      const existingIndex = prev.findIndex((js) => js.id === jobseekerId);

      if (existingIndex !== -1) {
        // If found, remove the jobseeker
        return prev.filter((js) => js.id !== jobseekerId);
      } else {
        // If not found, add the jobseeker
        return [
          ...prev,
          { id: jobseekerId, name: jobseekerName, jobseeker: jobseeker },
        ];
      }
    });
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
  };

  const handleAction = async (
    action,
    jobseekerId,
    jobseekerName,
    jobseeker,
  ) => {
    // First ensure we have the correct selection
    let newSelection;

    if (selectedJobseekers.length > 0) {
      newSelection = selectedJobseekers;
    }

    if (jobseeker) {
      // if the menu is called from the single jobseeker menu
      newSelection = [
        { id: jobseekerId, name: jobseekerName, jobseeker: jobseeker },
      ];
    }
    setSelectedJobseekers(newSelection);

    switch (action) {
      case "Invite to Interview":
        const interviewData = {
          id: selectedJob,
          name: jobs.find((job) => job._id === selectedJob)?.jobReference,
          candidates: newSelection,
        };
        setInterviewData(interviewData);
        setModalState({ isOpen: true, type: "interview" });
        break;

      case "Add Notes":
        setModalState({ isOpen: true, type: "note" });
        break;

      case "Call":
        window.location = `tel:${jobseeker.phone}`;
        break;

      case "Mark as Hired":
        if (window.confirm("Are you sure?")) {
          await hireJobseeker(jobseekerId);
          notify("Jobseeker is marked as Hired", "success");
        }
        break;

      case "Reject":
        if (window.confirm("Are you sure?")) {
          await rejectJobseeker(jobseekerId);
          notify("Jobseeker is marked as Rejected", "success");
        }
        break;

      case "Message":
        setModalState({ isOpen: true, type: "message" });
        break;

      case "Chase up Questionnaire":
        if (window.confirm("Are you sure?")) {
          try {
            const status = await chaseJobseekerForQuestionnaire(
              newSelection,
              selectedJob,
            );
            notify(
              status
                ? "Questionnaire invite is sent"
                : "Questionnaire not found for the job",
              status ? "success" : "error",
            );
          } catch (error) {
            notify("Failed to send questionnaire", "error");
            console.error("Questionnaire error:", error);
          }
        }
        break;
      case "Tag":
        setModalState({ isOpen: true, type: "tag" });
        break;

      default:
        console.log("Unhandled action:", action);
    }
    /*
  } else {
    // Handle actions with existing selection
    switch (action) {
      case "Invite to Interview":
        const interviewData = {
          id: selectedJob,
          name: jobs.find((job) => job._id === selectedJob)?.jobReference,
          candidates: selectedJobseekers,
        };
        setInterviewData(interviewData);
        setModalState({ isOpen: true, type: "interview" });
        break;

      case "Add Notes":
        setModalState({ isOpen: true, type: "note" });
        break;

      case "Call":
        window.location = `tel:${jobseeker.phone}`;
        break;

      case "Mark as Hired":
        if (window.confirm("Are you sure?")) {
          await hireJobseeker(jobseekerId);
          notify("Jobseeker is marked as Hired", "success");
        }
        break;

      case "Reject":
        if (window.confirm("Are you sure?")) {
          await rejectJobseeker(jobseekerId);
          notify("Jobseeker is marked as Rejected", "success");
        }
        break;

      case "Message":
        setModalState({ isOpen: true, type: "message" });
        break;

      case "Chase up Questionnaire":
        if (window.confirm("Are you sure?")) {
          try {
            const status = await chaseJobseekerForQuestionnaire(
              selectedJobseekers,
              selectedJob,
            );
            notify(
              status
                ? "Questionnaire invite is sent"
                : "Questionnaire not found for the job",
              status ? "success" : "error",
            );
          } catch (error) {
            notify("Failed to send questionnaire", "error");
            console.error("Questionnaire error:", error);
          }
        }
        break;
      case "Tag":
        setModalState({ isOpen: true, type: "tag" });
        break;

      default:
        console.log("Unhandled action:", action);
    }
  }
  */
  };

  const filteredJobseekers = jobseekers
    .filter(
      (js) =>
        filterStatus.includes("all") ||
        filterStatus.includes(js.applicationStatus),
    )
    .filter(
      (js) =>
        filterQuestionnaireStatus.includes("all") ||
        filterQuestionnaireStatus.includes(js.questionnaireStatus),
    )
    .filter(
      (js) =>
        filterTags.includes("all") ||
        js.tags.some((tag) => filterTags.includes(tag.tag.name)), // Use `.some()` instead of `.filter()`
    );
  if (isLoading) {
    return <div className="text-center py-4">Loading jobseekers...</div>;
  }

  if (error) {
    return <div className="text-center py-4 text-red-500">{error}</div>;
  }

  const handleNoteSubmit = async (newNote) => {
    try {
      // Send the newNote to your API endpoint
      const token = localStorage.getItem("token");
      await api.post(`/api/notes`, newNote, {
        headers: { Authorization: `Bearer ${token}` },
      });
      notify("Note is added to Selected Jobseeker", "success");
    } catch (error) {
      console.error("Error adding note:", error);
    }
  };

  const handleAddTags = (tagList) => {
    const jobseekers = filteredJobseekers.map((jobseeker) => {
      const newTags = tagList.filter((tag) => {
        return tag.jobseeker === jobseeker._id ? true : false;
      });

      if (newTags.length > 0) {
        jobseeker.tags.push(...newTags);
      }
      return jobseeker;
    });
    setJobseekers(jobseekers);
  };
  const renderModalContent = () => {
    switch (modalState.type) {
      case "interview":
        return (
          <InterviewActionButtons
            interviewData={interviewData}
            handleCloseModal={handleCloseModal}
            currentCompany={currentCompany}
          />
          /*
          <EventCreationForm
            jobName={interviewData.name}
            jobId={interviewData.id}
            candidates={interviewData.candidates}
            onClose={handleCloseModal}
            currentCompany={currentCompany}
          />
          */
        );
      case "note":
        return (
          <NoteAddForm
            jobseekerId={selectedJobseekers}
            companyUserId={currentUser}
            onSubmit={handleNoteSubmit}
          />
        );
      case "message":
        return (
          <MessageInterface
            worker={selectedJobseekers}
            companyUser={currentUser}
            initialJobseekers={initialJobseekers}
          />
        );
      case "tag":
        return (
          <div className="w-full">
            <h3 className="font-lg font-bold pb-4">Add Tags</h3>
            <TagInterface
              jobseekers={selectedJobseekers}
              companyUser={currentUser}
              currentCompany={currentCompany}
              jobseekerList={initialJobseekers}
              handleAddTags={handleAddTags}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const handleSortBy = (sortBy) => {
    resetSortUI();
    let sortIndex = "";
    switch (sortBy) {
      case "name":
        setSortName(true);
        sortIndex = "name";
        break;
      case "appliedBy":
        setSortApplied(true);
        sortIndex = "createdAt";
        break;
      case "qStatus":
        setSortQStatus(true);
        sortIndex = "questionnaireStatus";
        break;
      case "score":
        setSortScore(true);
        sortIndex = "score";
        break;
    }
    const list = sortedJobseekers(filteredJobseekers, sortBy, sortOrder);
    setJobseekers(list);
    setSortOrder(() => {
      return sortOrder === "asc" ? "desc" : "asc";
    });
  };

  const resetSortUI = () => {
    setSortScore(false);
    setSortQStatus(false);
    setSortApplied(false);
    setSortName(false);
  };

  const resetSorting = (e) => {
    e.preventDefault();
    e.stopPropagation();
    resetSortUI();
    const list = sortedJobseekers(initialJobseekers);
    setJobseekers(list);
  };

  const handleSearch = (e) => {
    resetSortUI();
    const searchFor = e.target.value;
    let searchjobseekers = initialJobseekers;

    const jobseekers = searchjobseekers.filter((jobseeker) => {
      if (jobseeker.name.toLowerCase().includes(searchFor)) {
        return true;
      }
      if (jobseeker.jobTitle?.toLowerCase().includes(searchFor)) {
        return true;
      }
      if (jobseeker.location?.toLowerCase().includes(searchFor)) {
        return true;
      }
      return false;
    });
    setJobseekers(() => {
      return sortedJobseekers(jobseekers);
    });
  };

  const removeTag = (jobseekerId, tagId) => {
    const jobseekers = filteredJobseekers.map((j) => {
      if (j._id === jobseekerId) {
        const f_tags = j.tags.filter((tag) => {
          return tag._id === tagId ? false : true;
        });
        j.tags = f_tags;
      }
      return j;
    });
    setJobseekers(jobseekers);
  };

  const handleTagDelete = async (jobseekerId, tagId) => {
    await deleteJobseekerTag(tagId);
    removeTag(jobseekerId, tagId);
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-col lg:flex-row justify-between items-center mb-4">
        <h2 className="text-2xl font-bold mb-4 lg:mb-0">Jobseekers</h2>
        <div className="flex flex-col sm:flex-row items-center space-x-0 sm:space-x-4 space-y-2 sm:space-y-0 w-4/5 justify-end">
          <div
            className="flex items-center cursor-pointer w-auto"
            onClick={handleSelectAllChange}
          >
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAllChange}
              className="w-5 h-5 mr-2 rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
            <label className="cursor-pointer">Select All</label>
          </div>
          <JobseekerStatusMultiSelect
            onChange={(selectedOptions) => {
              if (selectedOptions.length === 0) {
                setFilterStatus(["all"]);
                setFilterQuestionnaireStatus(["all"]);
                return true;
              }
              const statusFilter = [
                "Applied",
                "Interviewed",
                "Interview Booked",
                "Hired",
                "Rejected",
              ];
              let filterStatus = [];
              let filterQuestionnaireStatus = [];
              selectedOptions.forEach((status) => {
                if (statusFilter.includes(status.value)) {
                  filterStatus.push(status.value);
                } else {
                  filterQuestionnaireStatus.push(status.value);
                }
              });
              if (filterStatus.length === 0) filterStatus = ["all"];
              if (filterQuestionnaireStatus.length === 0)
                filterQuestionnaireStatus = ["all"];

              setFilterStatus(filterStatus);
              setFilterQuestionnaireStatus(filterQuestionnaireStatus);
            }}
          />
          <div className="w-60">
            <Select
              isMulti={true}
              name="tags"
              options={tags}
              className="w-auto basic-multi-select w-60"
              placeholder="Filter by Tags"
              onChange={(selectedOptions) => {
                if (selectedOptions.length === 0) {
                  setFilterTags(["all"]);
                  return true;
                }
                setFilterTags(selectedOptions.map((option) => option.value));
              }}
            />
          </div>
          <select
            onChange={(e) => handleAction(e.target.value)}
            className="border rounded p-2 w-full sm:w-auto"
            disabled={selectedJobseekers.length === 0}
          >
            <option value="">Bulk Actions</option>
            <option value="Invite to Interview">Invite to Interview</option>
            <option value="Chase up Questionnaire">
              Chase up Questionnaire
            </option>
            <option value="Reject">Reject</option>
            <option value="Add Notes">Add Notes</option>
            <option value="Mark as Hired">Mark as Hired</option>
            {/*
            <option value="Call">Call</option>*/}
            <option value="Message">Message</option>
            <option value="Ask Further Questions">Ask Further Questions</option>
          </select>
        </div>
      </div>
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <nav className="bg-white shadow-sm p-4">
          {/* Added container div with left padding/margin */}
          <div className="max-w-8xl">
            <div className="flex items-center justify-between w-full px-4 text-sm">
              <div className="flex gap-2 w-1/3">
                <button
                  onClick={() => {
                    handleSortBy("name");
                  }}
                  className="flex items-center gap-1 px-3 py-2 text-gray-700 hover:text-gray-900 hover:bg-gray-100 rounded-md transition-colors duration-200"
                >
                  Name
                  <ArrowUpDown className="h-4 w-4" />
                  {sortName ? (
                    <a
                      href="#"
                      onClick={resetSorting}
                      className="hover:shadow-lg px-2 py-2 rounded-md transition-colors duration-200"
                    >
                      <X className="h-4 w-4" />
                    </a>
                  ) : (
                    ""
                  )}
                </button>
                <input
                  type="text"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md border-gray-300"
                  placeholder="search name, title and location"
                  onChange={handleSearch}
                />
              </div>
              <div className="flex items-center">
                <div className="flex items-center space-x-6">
                  <button
                    onClick={() => {
                      handleSortBy("appliedBy");
                    }}
                    className="flex items-center gap-1 px-3 py-2 text-gray-700 hover:text-gray-900 hover:bg-gray-100 rounded-md transition-colors duration-200"
                  >
                    Applied on
                    <ArrowUpDown className="h-4 w-4" />
                    {sortApplied ? (
                      <a
                        href="#"
                        onClick={resetSorting}
                        className="hover:shadow-lg px-2 py-2 rounded-md transition-colors duration-200"
                      >
                        <X className="h-4 w-4" />
                      </a>
                    ) : (
                      ""
                    )}
                  </button>
                  <button
                    onClick={() => {
                      handleSortBy("qStatus");
                    }}
                    className="flex items-center gap-2 px-3 py-2 text-gray-700 hover:text-gray-900 hover:bg-gray-100 rounded-md transition-colors duration-200"
                  >
                    Questionnaire
                    <ArrowUpDown className="h-4 w-4" />
                    {sortQStatus ? (
                      <a
                        href="#"
                        onClick={resetSorting}
                        className="hover:shadow-lg px-2 py-2 rounded-md transition-colors duration-200"
                      >
                        <X className="h-4 w-4" />
                      </a>
                    ) : (
                      ""
                    )}
                  </button>
                  {/* added to maintain gaps */}
                  <button className="flex items-center gap-2 px-3 py-2"></button>
                  {/* added to maintain gaps */}
                </div>
                <div className="ml-8">
                  <button
                    onClick={() => {
                      handleSortBy("score");
                    }}
                    className="flex items-center gap-2 px-3 py-2 text-gray-700 hover:text-gray-900 hover:bg-gray-100 rounded-md transition-colors duration-200"
                  >
                    Score
                    <ArrowUpDown className="h-4 w-4" />
                    {sortScore ? (
                      <a
                        href="#"
                        onClick={resetSorting}
                        className="hover:shadow-lg px-2 py-2 rounded-md transition-colors duration-200"
                      >
                        <X className="h-4 w-4" />
                      </a>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {filteredJobseekers.length === 0 ? (
          <div className="text-center py-4">
            No jobseekers found for this job.
          </div>
        ) : (
          ""
        )}
        {filteredJobseekers.map((jobseeker) => (
          <div
            key={jobseeker._id}
            className="border-b border-gray-200 last:border-b-0"
          >
            <div className="flex flex-col sm:flex-row items-center p-4 hover:bg-gray-50">
              <div className="mr-4" onClick={(e) => e.stopPropagation()}>
                <input
                  type="checkbox"
                  checked={selectedJobseekers.some(
                    (element) => element.id == jobseeker._id,
                  )}
                  onChange={() =>
                    handleCheckboxChange(
                      jobseeker._id,
                      jobseeker.name,
                      jobseeker,
                    )
                  }
                  className="w-5 h-5 rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                />
              </div>

              {jobseeker.avatar ? (
                <img
                  src={`${API_BASE_URL}${jobseeker.avatar?.replace(/^\//, "")}`}
                  alt={jobseeker.name}
                  className="w-16 h-16 rounded-full mr-4 object-cover cursor-pointer"
                  onClick={() =>
                    setExpandedJobseeker(
                      expandedJobseeker === jobseeker._id
                        ? null
                        : jobseeker._id,
                    )
                  }
                />
              ) : (
                <span
                  className="w-10 h-10 bg-indigo-500 text-white rounded-full flex items-center justify-center font-bold text-sm mr-4 cursor-pointer"
                  onClick={() =>
                    setExpandedJobseeker(
                      expandedJobseeker === jobseeker._id
                        ? null
                        : jobseeker._id,
                    )
                  }
                >
                  {getNameInitials(jobseeker.name)}
                </span>
              )}
              <div
                className="flex-grow cursor-pointer text-center sm:text-left"
                onClick={() =>
                  setExpandedJobseeker(
                    expandedJobseeker === jobseeker._id ? null : jobseeker._id,
                  )
                }
              >
                <h3 className="font-semibold">
                  {capitalizeWords(jobseeker.name)}
                </h3>
                <p className="text-sm text-gray-500">
                  {jobseeker.jobTitle
                    ? `${jobseeker.jobTitle} • ${jobseeker.location}`
                    : " - "}
                </p>
                <div className="block">
                  {jobseeker.tags.map((tag) => (
                    <TagComponent
                      key={tag._id}
                      text={tag.tag.name}
                      onDelete={(e) => {
                        e.stopPropagation();
                        handleTagDelete(jobseeker._id, tag._id);
                      }}
                    />
                  ))}
                </div>
              </div>
              <div className="flex flex-col sm:flex-row items-center space-x-0 sm:space-x-4 space-y-2 sm:space-y-0 mt-2 sm:mt-0">
                {/*
                <span
                  className="inline-flex items-center px-2 py-1 text-xs rounded-full bg-gray-200 text-gray-800 cursor-pointer"
                  onClick={() => {
                    handleAction(
                      "Message",
                      jobseeker._id,
                      jobseeker.name,
                      jobseeker,
                    );
                  }}
                >
                  <MessageSquare size={12} />
                  <label className="ml-1 cursor-pointer">Messages</label>
                </span>
                  */}
                <span
                  title="applied on"
                  className={`px-2 py-1 rounded-full text-xs w-24 text-center`}
                >
                  {new Date(jobseeker.createdAt).toLocaleDateString()}
                </span>
                <span
                  onMouseEnter={() => setTooltipOpen(true)}
                  onMouseOut={() => setTooltipOpen(false)}
                  data-tooltip-id="questionnaireStatusTooltip"
                  data-tooltip-content={`Questionnaire: ${jobseeker.detailedInfo.questionnaireResponsesCompletedBy ? jobseeker.detailedInfo.questionnaireResponsesCompletedBy : ""} (${jobseeker.questionnaireCompletionRate}%)`}
                  className={`px-2 py-1 rounded-full text-xs ${jobseeker.questionnaireStatus ? getStatusColor(jobseeker.questionnaireStatus) : ""} w-24 text-center`}
                >
                  {jobseeker.questionnaireStatus
                    .replace("_", " ")
                    .charAt(0)
                    .toUpperCase() +
                    jobseeker.questionnaireStatus.replace("_", " ").slice(1)}
                </span>
                {jobseeker.questionnaireCompletionRate ? (
                  <ToolTipHtml
                    id="questionnaireStatusTooltip"
                    isOpen={tooltipOpen}
                  />
                ) : (
                  ""
                )}
                <span
                  className={`px-2 py-1 rounded-full text-xs ${jobseeker.applicationStatus ? getApplicationStatusColor(jobseeker.applicationStatus) : ""} w-24 text-center`}
                >
                  {jobseeker.applicationStatus.charAt(0).toUpperCase() +
                    jobseeker.applicationStatus.slice(1)}
                </span>
                <div
                  className={`w-10 h-10 rounded-full border-2 ${getScoreColor(jobseeker.score ? jobseeker.score : 0)} flex items-center justify-center font-semibold`}
                >
                  {jobseeker.score ?? 0}
                </div>
                <ActionMenu
                  jobseekerId={jobseeker._id}
                  jobseekerName={jobseeker.name}
                  jobseeker={jobseeker}
                  onAction={handleAction}
                />
              </div>
            </div>
            {expandedJobseeker === jobseeker._id && (
              <ExpandedJobseekerView
                jobseeker={jobseeker}
                selectedJob={selectedJob}
                jobseekersAverageScore={jobseekerAverageScore}
                initialJobseekers={initialJobseekers}
                companyUser={currentUser}
              />
            )}
          </div>
        ))}
      </div>
      <Modal
        isOpen={modalState.isOpen}
        onRequestClose={handleCloseModal}
        contentLabel={`JobSeeker Action: ${modalState.type}`}
        className={`w-auto mx-auto mt-20 p-6 bg-white rounded-lg shadow-xl ${modalState.type === "message" ? "min-w-1/3" : ""}`}
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        {renderModalContent()}
      </Modal>
    </div>
  );
};

export default JobseekerList;
