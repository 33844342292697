import React, { useState, useEffect } from "react";
import { FileText, StarOff, Star } from "lucide-react";
import { getReferences, capitalizeWords } from "../utils";

const ReferenceList = ({ candidateId }) => {
  console.log(candidateId);
  const [references, setReferences] = useState([]);

  useEffect(() => {
    const getCandidateReferences = async () => {
      const references = await getReferences(candidateId);
      console.log(references, "references ");
      setReferences(references);
    };
    getCandidateReferences();
  }, []);
  return (
    <div className="p-4 space-y-6 bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg">
      <h3 className="text-2xl font-bold mb-6 text-indigo-800 flex items-center">
        <FileText className="mr-2" />
        Reference Requests
      </h3>
      {references.length > 0 &&
        references.map((reference) => (
          <div
            key={reference.id || Math.random().toString()}
            className="bg-white p-6 rounded-lg shadow-md mb-6"
          >
            <h4 className="text-lg font-semibold mb-4 text-indigo-800 flex items-center">
              By {reference.contact_name}{" "}
              {reference.company_name && reference.company_name !== "undefined"
                ? `(${reference.company_name})`
                : ""}
            </h4>
            <div className="grid grid-rows-1 gap-4 pl-5">
              {reference?.reference &&
                reference.reference.status !== "rejected" &&
                Object.entries(reference.reference.ratings || {}).map(
                  ([key, rating]) => (
                    <span className="flex flex-row items-center" key={key}>
                      <strong className="w-40">{capitalizeWords(key)}:</strong>
                      <div className="flex">
                        {[...Array(5).keys()].map((count) =>
                          rating >= count + 1 ? (
                            <Star
                              key={count}
                              color="gold"
                              fill="gold"
                              strokeWidth={0}
                              size={24}
                            />
                          ) : (
                            <Star key={count} size={22} />
                          ),
                        )}
                      </div>
                    </span>
                  ),
                )}
              {reference?.reference && (
                <div>
                  <strong>Comment: </strong>
                  {reference.reference.comment !== "" ? (
                    reference.reference.comment
                  ) : (
                    <span className="text-gray-500 italic">
                      Not provided yet
                    </span>
                  )}
                </div>
              )}
              {!reference?.reference && (
                <div className="text-gray-500 italic">
                  No reference information provided yet.
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default ReferenceList;
