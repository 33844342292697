import React, { useState, useEffect } from "react";
import {
  FileText,
  Zap,
  Shield,
  Clipboard,
  Truck,
  Check,
  Building2,
} from "lucide-react";
import { getChecklistForJob, saveCheckList } from "./utils";
import { useNotification } from "./CustomNotificationSystem";

const Checklist = ({ jobId, jobseeker }) => {
  const [hiringStages, setHiringStages] = useState([]);
  const notify = useNotification();

  useEffect(() => {
    // Set up hiring stages with tasks specific to hiring an electrician
    async function getCheckList(jobId) {
      const job_checklist = await getChecklistForJob(jobId);
      const preDefinedChecklist = [
        {
          name: "Pre-Employment",
          tasks: [
            { id: 15, text: "Background check", completed: false },
            { id: 16, text: "Contract finalised", completed: false },
            { id: 18, text: "First day orientation", completed: false },
          ],
        },
        {
          name: "Final Preparations",
          tasks: [
            { id: 19, text: "WhatsApp reminder", completed: false },
            { id: 20, text: "Transportation confirmed", completed: false },
            { id: 21, text: "First day instructions", completed: false },
            { id: 22, text: "Supervisor informed", completed: false },
          ],
        },
      ];

      let checklist;
      console.log(jobseeker.hiring_checklist, "jobseeker.hiring_checklist");
      if (jobseeker.hiring_checklist && jobseeker.hiring_checklist.length > 0) {
        checklist = jobseeker.hiring_checklist;
      } else {
        checklist = [...job_checklist, ...preDefinedChecklist];
      }
      setHiringStages(checklist);
    }
    getCheckList(jobId);
  }, [jobId]);

  const toggleTask = (stageIndex, taskId) => {
    console.log(`Toggling task ${taskId} in stage ${stageIndex}`);
    setHiringStages((prevStages) => {
      // Copy the previous stages array
      const newStages = [...prevStages];

      // Copy the tasks array immutably
      const newTasks = [...newStages[stageIndex].tasks];

      // Find the task index
      const taskIndex = newTasks.findIndex((task) => task.id === taskId);

      // Toggle the completed status immutably
      if (taskIndex !== -1) {
        newTasks[taskIndex] = {
          ...newTasks[taskIndex],
          completed: !newTasks[taskIndex].completed,
        };

        // Update the tasks in the stage immutably
        newStages[stageIndex] = {
          ...newStages[stageIndex],
          tasks: newTasks,
        };
      }

      return newStages;
    });
  };

  const handleSave = async () => {
    // Logic to save the checklist state
    await saveCheckList(jobId, jobseeker._id, hiringStages);
    jobseeker.hiring_checklist = hiringStages;
    notify("Checklist is saved for the jobseeker", "success");
  };

  const category_icon = {
    "by company": <Building2 className="mr-2 text-blue-500" />,
    "documentation check": <FileText className="mr-2 text-blue-500" />,
    "experience and skills": <Zap className="mr-2 text-blue-500" />,
    "safety compliance": <Shield className="mr-2 text-blue-500" />,
    "pre-employment": <Clipboard className="mr-2 text-blue-500" />,
    "final preparations": <Truck className="mr-2 text-blue-500" />,
  };

  return (
    <div className="p-4 space-y-6 bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg">
      <h3 className="text-2xl font-bold mb-6 text-indigo-800 flex items-center">
        <Clipboard className="mr-2" /> Hiring Checklist for {jobseeker.name}
      </h3>

      {/* Hiring Stages Section */}
      {hiringStages.map((stage, stageIndex) => (
        <div
          key={stageIndex}
          className="bg-white p-6 rounded-lg shadow-md mb-6"
        >
          <h4 className="text-lg font-semibold mb-4 text-indigo-800 flex items-center">
            {category_icon[stage.name.toLowerCase()]}
            {stage.name}
          </h4>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {stage.tasks.map((task) => (
              <div
                key={task.id}
                className={`p-3 rounded-lg shadow cursor-pointer transition-all duration-200 ${task.completed
                    ? "bg-green-100 border-green-500"
                    : "bg-gray-100 border-gray-300"
                  } ${task.critical ? "border-l-4" : "border"}`}
                onClick={() => toggleTask(stageIndex, task.id)}
              >
                <div className="flex items-center justify-between">
                  <span
                    className={`${task.completed ? "line-through text-gray-500" : "text-gray-800"} ${task.critical ? "font-semibold" : ""}`}
                  >
                    {task.text}
                  </span>
                  {task.completed && (
                    <Check className="text-green-500" size={20} />
                  )}
                </div>
                {task.critical && (
                  <span className="text-red-500 text-xs mt-1 block">
                    (Critical)
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
      <button
        onClick={handleSave}
        className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 focus:outline-none"
      >
        Save Checklist
      </button>
    </div>
  );
};

export default Checklist;
