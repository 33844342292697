import React from 'react';
import { MessageSquare, ThumbsUp, ThumbsDown, Star, Award, Info } from 'lucide-react';

const InterviewData = {
  transcript: [
    { speaker: 'Interviewer', text: "Hello John, thank you for coming in today. Can you tell me about your experience as an electrician?" },
    { speaker: 'John Doe', text: "Hello, thank you for having me. I've been working as an electrician for 5 years now. I started as an apprentice and worked my way up to a licensed electrician. I've worked on both residential and commercial projects, including new constructions and renovations." },
    { speaker: 'Interviewer', text: "That's great. Can you describe a challenging project you've worked on recently?" },
    { speaker: 'John Doe', text: "Sure. Last year, I worked on retrofitting an old factory with a new electrical system. The challenge was integrating modern equipment with the existing infrastructure while ensuring everything met current safety codes. We had to be creative with our wiring solutions and work closely with the building engineers to make it all come together." },
    { speaker: 'Interviewer', text: "How do you ensure safety on the job?" },
    { speaker: 'John Doe', text: "Safety is my top priority. I always follow proper lockout/tagout procedures, use appropriate PPE, and double-check my work. I stay updated on the latest safety regulations and never take shortcuts, no matter how small the job." },
    { speaker: 'Interviewer', text: "Can you explain the difference between GFCI and AFCI?" },
    { speaker: 'John Doe', text: "Certainly. GFCI stands for Ground Fault Circuit Interrupter, which protects against ground faults by detecting imbalances in the current. It's commonly used in areas near water. AFCI, or Arc Fault Circuit Interrupter, protects against electrical fires caused by arcing faults in the wiring. It's required in many living areas of homes." },
    { speaker: 'Interviewer', text: "How do you handle difficult clients or coworkers?" },
    { speaker: 'John Doe', text: "I always try to maintain a professional attitude and open communication. If there's a disagreement, I listen to their concerns and try to find a solution that works for everyone while still adhering to safety standards and regulations. If necessary, I involve a supervisor to mediate the situation." },
  ],
  analysis: {
    positivePoints: [
      "Demonstrated solid experience in both residential and commercial projects",
      "Showed problem-solving skills in describing the factory retrofitting project",
      "Strong emphasis on safety procedures and staying updated on regulations",
      "Displayed good technical knowledge in explaining GFCI and AFCI",
      "Showed professionalism and good communication skills in handling difficult situations",
    ],
    negativePoints: [
      "Could have provided more specific examples of safety procedures implemented",
      "Didn't mention any certifications or continued education in the field",
      "Could have elaborated more on experience with specific types of electrical systems",
    ],
    neutralObservations: [
      "Mentioned 5 years of experience, which is moderate for the role",
      "Showed familiarity with both GFCI and AFCI, but didn't elaborate on their application in different scenarios",
      "Expressed willingness to involve supervisors in conflict resolution, indicating awareness of hierarchy but potential reluctance to handle issues independently",
      "Focused more on residential and commercial experience, with less emphasis on industrial projects",
    ],
    summary: "John demonstrated a good balance of technical knowledge, practical experience, and soft skills during the interview. His emphasis on safety and problem-solving abilities are particularly noteworthy. While he could have provided more detailed examples in some areas, overall, he presents as a competent and experienced electrician.",
    scoring: [
      { category: "Technical Knowledge", score: 8.5 },
      { category: "Safety Awareness", score: 9 },
      { category: "Problem Solving", score: 8 },
      { category: "Communication Skills", score: 8.5 },
      { category: "Experience", score: 8 },
    ]
  }
};

const Interview = () => {
  return (
    <div className="p-6 space-y-8 bg-gradient-to-br from-blue-50 to-indigo-50 rounded-lg">
      <h3 className="text-3xl font-bold mb-8 text-indigo-800 flex items-center">
        <MessageSquare className="mr-3" /> Interview
      </h3>
      
      <div className="bg-white p-8 rounded-lg shadow-md mb-8">
        <h4 className="text-xl font-semibold mb-6 text-indigo-800 border-b pb-2">Transcript</h4>
        {InterviewData.transcript.map((entry, index) => (
          <div key={index} className={`mb-6 ${entry.speaker === 'Interviewer' ? 'text-blue-600' : 'text-green-600'}`}>
            <strong className="text-lg">{entry.speaker}:</strong> <span className="text-gray-700">{entry.text}</span>
          </div>
        ))}
      </div>

      <div className="bg-white p-8 rounded-lg shadow-md mb-8">
        <h4 className="text-xl font-semibold mb-6 text-indigo-800 border-b pb-2">Analysis</h4>
        
        <div className="mb-8">
          <h5 className="font-semibold text-green-600 flex items-center mb-4 text-lg">
            <ThumbsUp className="mr-2" /> Positive Points
          </h5>
          <ul className="list-disc pl-6 space-y-2">
            {InterviewData.analysis.positivePoints.map((point, index) => (
              <li key={index} className="text-gray-700">{point}</li>
            ))}
          </ul>
        </div>

        <div className="mb-8">
          <h5 className="font-semibold text-red-600 flex items-center mb-4 text-lg">
            <ThumbsDown className="mr-2" /> Areas for Improvement
          </h5>
          <ul className="list-disc pl-6 space-y-2">
            {InterviewData.analysis.negativePoints.map((point, index) => (
              <li key={index} className="text-gray-700">{point}</li>
            ))}
          </ul>
        </div>

        <div className="mb-8">
          <h5 className="font-semibold text-orange-500 flex items-center mb-4 text-lg">
            <Info className="mr-2" /> Neutral Observations
          </h5>
          <ul className="list-disc pl-6 space-y-2">
            {InterviewData.analysis.neutralObservations.map((point, index) => (
              <li key={index} className="text-gray-700">{point}</li>
            ))}
          </ul>
        </div>

        <div className="mb-8">
          <h5 className="font-semibold text-indigo-800 flex items-center mb-4 text-lg">
            <Star className="mr-2" /> Summary
          </h5>
          <p className="text-gray-700 leading-relaxed">{InterviewData.analysis.summary}</p>
        </div>

        <div>
          <h5 className="font-semibold text-indigo-800 flex items-center mb-4 text-lg">
            <Award className="mr-2" /> Scoring
          </h5>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {InterviewData.analysis.scoring.map((item, index) => (
              <div key={index} className="flex justify-between items-center bg-gray-50 p-3 rounded">
                <span className="text-gray-700">{item.category}:</span>
                <span className="font-semibold text-indigo-600">{item.score}/10</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Interview;
