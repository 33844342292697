import React, { useState } from "react";
import { MessageSquare, Calendar, Info, PhoneCall } from "lucide-react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import EventCreationForm from "../EventCreationForm";
import { initInterviewByWhatsapp } from "../utils/scheduleInterview";

const InterviewActionButtons = ({
  interviewData,
  handleCloseModal,
  currentCompany,
}) => {
  console.log(interviewData);
  const [showEventForm, setShowEventForm] = useState(false);
  const handleWhatsappClick = async () => {
    if (
      !window.confirm("Are you sure? it will send out a message over Whatsapp?")
    ) {
      return false;
    }

    await initInterviewByWhatsapp(interviewData.candidates);
  };

  const handleAddEventClick = () => {
    setShowEventForm(true);
  };

  return !showEventForm ? (
    <div className="flex flex-col items-center justify-center p-6 gap-4 max-w-md mx-auto">
      <h2 className="text-xl font-semibold mb-4">
        Choose an Option to Schedule Interview
      </h2>
      <div className="flex flex-col w-full gap-3 justify-center">
        <button
          onClick={handleWhatsappClick}
          className="flex items-center justify-center gap-2 bg-green-500 hover:bg-green-600 text-white py-3 px-6 rounded-lg transition-colors duration-300 font-medium"
        >
          <MessageSquare size={20} />
          By using WhatsApp
          <div
            className="ml-1 p-1 bg-white bg-opacity-20 rounded-full"
            data-tooltip-id="whatsapp-tooltip"
          >
            <Info size={20} />
          </div>
        </button>

        <button
          onClick={handleAddEventClick}
          className="flex items-center justify-center gap-2 bg-purple-500 hover:bg-purple-600 text-white py-3 px-6 rounded-lg transition-colors duration-300 font-medium"
        >
          <Calendar size={20} />
          Add new Event
          <div
            className="ml-1 p-1 bg-white bg-opacity-20 rounded-full"
            data-tooltip-id="event-tooltip"
          >
            <Info size={20} />
          </div>
        </button>
        {interviewData.candidates.length === 1 ? (
          <a
            href={`tel:${interviewData.candidates[0].jobseeker.phone}`}
            className="flex items-center justify-center gap-2 bg-purple-500 hover:bg-purple-600 text-white py-3 px-6 rounded-lg transition-colors duration-300 font-medium"
          >
            <PhoneCall size={20} />
            Call Directly
            <div
              className="ml-1 p-1 bg-white bg-opacity-20 rounded-full"
              data-tooltip-id="phone-tooltip"
            >
              <Info size={20} />
            </div>
          </a>
        ) : (
          ""
        )}
      </div>
      {/* Separate tooltip components */}
      <ReactTooltip
        id="whatsapp-tooltip"
        place="top"
        content="Check Calendly or Google and send the available slots to the jobseeker via WhatsApp."
      />
      <ReactTooltip
        id="event-tooltip"
        place="top"
        content="You can add an event through a form using Calendly or Google Calendar."
      />
      <ReactTooltip
        id="phone-tooltip"
        place="top"
        content="You can call direclty to the candidates"
      />
    </div>
  ) : (
    <EventCreationForm
      jobName={interviewData.name}
      jobId={interviewData.id}
      candidates={interviewData.candidates}
      onClose={handleCloseModal}
      currentCompany={currentCompany}
    />
  );
};

export default InterviewActionButtons;
