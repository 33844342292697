import { api } from "./api";

// [{id, name}, {id, name}]
export const initInterviewByWhatsapp = async (jobseekerList) => {
  if (typeof jobseekerList !== "object") {
    throw new Error("Provided jobseeker should be object");
  }
  const jobseeker = jobseekerList.map(({ id, name }) => ({
    id,
    name,
  }));

  const response = await api.post(
    "/api/jobseekers/schedule/interview/with-whatsapp",
    {
      jobseeker,
    },
  );
};
