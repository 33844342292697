import React, {
  useState,
  useCallback,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { PlusCircle, Save, Brain } from "lucide-react";
import { Oval } from "react-loader-spinner";
import QuestionGenerator from "./QuestionGenerator";
import BackgroundLoadingBanner from "./QuestionnaireBgLoading";
import {
  AnswerTypes,
  DifficultyLevels,
  ImportanceLevels,
  QuestionTypes,
} from "./constants";
import {
  generateQuestionsWithAI,
  jobQuestionnaire,
  saveQuestion,
  questionnaireStatus,
  getJobDetails,
} from "./utils";

import { ConditionTypes } from "./constants";
import DraggableQuestionsList from "./DraggableQuestionList";
import { useNotification } from "./CustomNotificationSystem";
import QuestionCard from "./QuestionCard";

// ConditionValueInput Component

const QuestionnaireBuilder = forwardRef(
  ({ selectedJob, tempJobId, formData, countQuestionsAdded }, ref) => {
    //const [allQuestions, setAllQuestions] = useState([]);
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [expandedQuestions, setExpandedQuestions] = useState([]);
    const [displayLoader, setDisplayLoader] = useState(false);
    const [deletedQuestions, setDeletedQuestions] = useState([]);
    const [isBackgroundLoading, setIsBackgroundLoading] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(null);
    const notify = useNotification();
    const [isNewJob, setIsNewJob] = useState(false);
    const [isTempJob] = useState(() => {
      return !!tempJobId;
    });

    if (!selectedJob) {
      selectedJob = tempJobId; //either selectedJob is set or tempJobId
    }

    useImperativeHandle(
      ref,
      () =>
        ref
          ? {
              handleSave: () => {
                handleSave();
              },
            }
          : null,
      [filteredQuestions],
    );

    const handleToggleExpand = (questionId) => {
      setExpandedQuestions((prev) =>
        prev.includes(questionId)
          ? prev.filter((id) => id !== questionId)
          : [...prev, questionId],
      );
    };

    const handleAddFollowUp = (parentId) => {
      const newFollowUp = {
        id: Date.now().toString(),
        condition: ConditionTypes.YES_NO,
        condition_value: "yes",
        question: {
          _id: (Date.now() + 1).toString(),
          jobId: selectedJob,
          question_text: "New follow-up question",
          answer_type: AnswerTypes.OPEN_ENDED,
          difficulty_level: DifficultyLevels.MEDIUM,
          importance: ImportanceLevels.MEDIUM,
          evaluation_criteria: [],
          tags: [],
          follow_up_questions: [],
          question_type: "Assessment",
        },
      };

      setFilteredQuestions((prevQuestions) => {
        const updateRecursive = (questions) => {
          return questions.map((q) => {
            if (q._id === parentId) {
              return {
                ...q,
                follow_up_questions: [
                  ...(q.follow_up_questions || []),
                  newFollowUp,
                ],
              };
            }
            if (q.follow_up_questions && q.follow_up_questions.length > 0) {
              return {
                ...q,
                follow_up_questions: q.follow_up_questions.map((fu) => ({
                  ...fu,
                  question:
                    fu.question._id === parentId
                      ? {
                          ...fu.question,
                          follow_up_questions: [
                            ...(fu.question.follow_up_questions || []),
                            newFollowUp,
                          ],
                        }
                      : fu.question.follow_up_questions
                        ? {
                            ...fu.question,
                            follow_up_questions: updateRecursive([
                              fu.question,
                            ])[0].follow_up_questions,
                          }
                        : fu.question,
                })),
              };
            }
            return q;
          });
        };

        const updatedQuestions = updateRecursive(prevQuestions);
        //setAllQuestions((prev) => updateRecursive(prev));
        return updatedQuestions;
      });

      // Ensure the question is expanded after adding a follow-up
      setExpandedQuestions((prev) => {
        if (!prev.includes(parentId)) {
          return [...prev, parentId];
        }
        return prev;
      });
    };

    const handleUpdateFollowUp = (parentId, followUpId, field, value) => {
      setFilteredQuestions((prevQuestions) => {
        const updateRecursive = (questions) => {
          return questions.map((q) => {
            // Check if this is the parent question
            if (q._id === parentId) {
              return {
                ...q,
                follow_up_questions: q.follow_up_questions.map((fu) => {
                  if (fu.id === followUpId) {
                    if (field === "condition") {
                      let newConditionValue;
                      const condition_value = ConditionTypes[value];
                      switch (condition_value) {
                        case ConditionTypes.YES_NO:
                          newConditionValue = "yes";
                          break;
                        case ConditionTypes.KEYWORD:
                          newConditionValue = {
                            matchType: "matched",
                            keywords: "",
                          };
                          break;
                        case ConditionTypes.RATING:
                          newConditionValue = { type: "lessThan", value: 5 };
                          break;
                        case ConditionTypes.CONFIDENCE:
                        case ConditionTypes.COMPLETENESS:
                          newConditionValue = 50;
                          break;
                        case ConditionTypes.LENGTH:
                          newConditionValue = { type: "lessThan", value: 0 };
                          break;
                        default:
                          newConditionValue = "";
                      }
                      return {
                        ...fu,
                        condition: value,
                        condition_value: newConditionValue,
                      };
                    }
                    if (field === "question") {
                      return { ...fu, question: { ...fu.question, ...value } };
                    }
                    return { ...fu, [field]: value };
                  }
                  return fu;
                }),
              };
            }
            // Check follow-up questions recursively
            if (q.follow_up_questions && q.follow_up_questions.length > 0) {
              return {
                ...q,
                follow_up_questions: q.follow_up_questions.map((fu) => ({
                  ...fu,
                  question: updateRecursive([fu.question])[0],
                })),
              };
            }
            return q;
          });
        };

        const updatedQuestions = updateRecursive(prevQuestions);
        //setAllQuestions((prev) => updateRecursive(prev));
        //console.log(updatedQuestions);
        return updatedQuestions;
      });
    };

    useEffect(() => {
      if (countQuestionsAdded) countQuestionsAdded(filteredQuestions);
    }, [filteredQuestions]);

    useEffect(() => {
      async function fetchJob(selectedJob) {
        return await getJobDetails(selectedJob, isTempJob);
      }

      if (!isTempJob) {
        // if the job is temp than it's not saved yet
        fetchJob(selectedJob).then((job) => {
          if (
            job.questionnaireStatus &&
            job.questionnaireStatus === "generating"
          ) {
            setIsNewJob(true);
          }
        });
      }
    }, [selectedJob, isTempJob]);

    useEffect(() => {
      setIsBackgroundLoading(isNewJob);
    }, [isNewJob]);
    // Check questionnaire status periodically if it's a new job
    //
    useEffect(() => {
      let intervalId;

      const checkQuestionnaireStatus = async () => {
        try {
          const data = await questionnaireStatus(selectedJob);
          if (data.status === "completed") {
            setIsNewJob(false);
            const questions = await jobQuestionnaire(selectedJob);
            //setAllQuestions(questions); // Replace instead of append
            setFilteredQuestions(
              questions.filter((q) => q.jobId === selectedJob),
            );
            clearInterval(intervalId);
          } else if (data.status === "failed") {
            setIsNewJob(false);
            notify(
              "Failed to generate base questionnaire. You can still add questions manually.",
              "error",
            );
            clearInterval(intervalId);
          } else if (data.status === "generating") {
            setLoadingProgress("");
            //setAllQuestions([]);
          }
        } catch (error) {
          console.error("Error checking questionnaire status:", error);
        }
      };

      if (isNewJob) {
        intervalId = setInterval(checkQuestionnaireStatus, 3000);
        checkQuestionnaireStatus();
      }

      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }, [selectedJob, isNewJob, notify]);

    /*
useEffect(() => {
  // Only filter if we have questions to filter
  if (allQuestions.length > 0) {
    const filtered = allQuestions.filter((q) => q.jobId === selectedJob);
    setFilteredQuestions(filtered);
  }
}, [selectedJob, allQuestions]);
*/
    const handleQuestionsGenerated = useCallback(
      async (generatedQuestions) => {
        const db_questions = await jobQuestionnaire(selectedJob, isTempJob);
        setFilteredQuestions(db_questions);
        //setAllQuestions([...db_questions, ...generatedQuestions]); // Replace instead of mutating
      },
      [selectedJob, isTempJob],
    );

    const handleSave = async () => {
      const questionsJSON = {
        jobId: selectedJob,
        questions: JSON.parse(JSON.stringify(filteredQuestions)),
        deletedQuestions: deletedQuestions, // Include deleted questions array
      };

      const questions = await saveQuestion(questionsJSON);
      if (questions.questions) {
        setFilteredQuestions(questions.questions);
      }
      //clear the deleted questions array after successful save
      setDeletedQuestions([]);
      notify("Saved!", "success");
    };

    const handleDeleteQuestion = (questionId) => {
      if (
        window.confirm(
          "Are you sure you want to delete this question? This action cannot be undone.",
        )
      ) {
        setFilteredQuestions((prevQuestions) => {
          const deleteRecursive = (questions) => {
            // First, check if the current question is the one to delete
            const filteredQuestions = questions.filter(
              (q) => q._id !== questionId,
            );

            // Then check follow-up questions recursively
            return filteredQuestions.map((q) => {
              if (q.follow_up_questions && q.follow_up_questions.length > 0) {
                // First, check if any direct follow-up questions need to be deleted
                const filteredFollowUps = q.follow_up_questions.filter(
                  (fu) => fu.question._id !== questionId,
                );

                // Then recursively check the remaining follow-ups
                return {
                  ...q,
                  follow_up_questions: filteredFollowUps.map((fu) => ({
                    ...fu,
                    question: deleteRecursive([fu.question])[0],
                  })),
                };
              }
              return q;
            });
          };

          const updatedQuestions = deleteRecursive(prevQuestions);

          // Update allQuestions state as well
          //setAllQuestions((prev) => deleteRecursive(prev));

          // Add to deletedQuestions array
          setDeletedQuestions((prev) => [...prev, { _id: questionId }]);

          // Remove from expandedQuestions if it's expanded
          setExpandedQuestions((prev) =>
            prev.filter((id) => id !== questionId),
          );

          return updatedQuestions;
        });
      }
    };
    const addNewQuestion = () => {
      const newQuestion = {
        _id: Date.now().toString(),
        jobId: selectedJob,
        question_text: "New question",
        answer_type: AnswerTypes.OPEN_ENDED,
        difficulty_level: DifficultyLevels.MEDIUM,
        importance: ImportanceLevels.MEDIUM,
        evaluation_criteria: [],
        follow_up_questions: [],
        tags: [],
        category: "General",
        answer_scoring: { Yes: 1, No: 0 },
        question_type: "Assessment",
        order: filteredQuestions.length + 1,
      };
      //setAllQuestions((prev) => [...prev, newQuestion]);
      setFilteredQuestions((prev) => [...prev, newQuestion]);
    };

    const generateQuestions = async () => {
      if (!window.confirm("Are you sure?")) {
        return false;
      }
      setDisplayLoader(true);
      const questionnaire = await generateQuestionsWithAI(
        selectedJob,
        formData,
        tempJobId,
      );
      if (questionnaire.length === 0) {
        notify(
          "Failed to generate base questionnaire. You can still add questions manually.",
          "error",
        );
      }
      setFilteredQuestions(questionnaire);
      setDisplayLoader(false);
    };

    return (
      <div className="p-6 lg:p-8">
        {!tempJobId ? (
          <h2 className="text-2xl font-bold mb-6">
            Jobseeker Question Builder
          </h2>
        ) : (
          <p className="text-xl font-semibold mb-4 text-indigo-800 pb-2">
            Add Job-Specific Questions for Candidate Assessment
          </p>
        )}
        {/* Background Loading Banner */}
        <BackgroundLoadingBanner
          isLoading={isBackgroundLoading}
          progress={loadingProgress}
        />

        <QuestionGenerator
          key={`QuestionGenerator1`}
          onQuestionsGenerated={handleQuestionsGenerated}
        />
        {/* Questions List */}
        <DraggableQuestionsList
          questions={filteredQuestions}
          onQuestionsReorder={async (newQuestions) => {
            // Update both filtered and all questions while maintaining order
            const questionsJSON = {
              jobId: selectedJob,
              questions: JSON.parse(JSON.stringify(newQuestions)),
              deletedQuestions: deletedQuestions, // Include deleted questions array
              reorderd: true,
            };

            const questions = await saveQuestion(questionsJSON);
            if (questions.questions) {
              setFilteredQuestions(questions.questions);
            }
            //clear the deleted questions array after successful save
            setDeletedQuestions([]);

            /*
              setAllQuestions((prev) => {
                const updatedQuestions = [...prev];
                newQuestions.forEach((q) => {
                  const index = updatedQuestions.findIndex(
                    (uq) => uq._id === q._id,
                  );
                  if (index !== -1) {
                    updatedQuestions[index] = {
                      ...updatedQuestions[index],
                      order: q.order,
                    };
                  }
                });
                return updatedQuestions;
              });
              */
          }}
          renderItem={(question, index) => (
            <QuestionCard
              question={question}
              index={index}
              expanded={expandedQuestions.includes(question._id)}
              expandedQuestions={expandedQuestions}
              onToggleExpand={handleToggleExpand}
              onUpdate={(field, value) => {
                const updatedQuestions = filteredQuestions.map((q) => {
                  if (q._id === question._id) {
                    // Update the question only if the field being changed is "answer_type"
                    const updatedQuestion =
                      field === "answer_type"
                        ? {
                            ...q,
                            [field]: value,
                            question_type:
                              value === AnswerTypes.FILE ||
                              value === AnswerTypes.IMAGE
                                ? QuestionTypes.QUALIFICATION
                                : QuestionTypes.ASSESSMENT,
                          }
                        : { ...q, [field]: value };

                    return updatedQuestion;
                  }
                  return q;
                });
                setFilteredQuestions(updatedQuestions);

                /*
                  setAllQuestions((prev) =>
                    prev.map(
                      (q) => updatedQuestions.find((uq) => uq._id === q._id) || q,
                    ),
                  );
                  */
              }}
              onDelete={handleDeleteQuestion}
              onAddFollowUp={handleAddFollowUp}
              onUpdateFollowUp={handleUpdateFollowUp}
              parentExpanded={true}
            />
          )}
        />

        {/* Action Buttons */}
        <div className="flex justify-center space-x-4 border-t p-4 bg-white">
          <button
            onClick={addNewQuestion}
            className="px-6 py-2 text-white rounded hover:bg-opacity-90 flex items-center justify-center min-w-[200px]"
            style={{ backgroundColor: "#4c4def" }}
          >
            <PlusCircle size={20} className="mr-2" />
            Add New Question
          </button>

          <button
            onClick={generateQuestions}
            className="px-6 py-2 bg-green-500 text-white rounded hover:bg-green-600 flex items-center justify-center min-w-[200px]"
          >
            <Brain size={20} className="mr-2" />
            Generate with AI
            {displayLoader && (
              <Oval
                visible={true}
                height="16"
                width="16"
                color="white"
                ariaLabel="oval-loading"
                wrapperClass="ml-2"
              />
            )}
          </button>

          {!tempJobId ? (
            <button
              onClick={handleSave}
              className="px-6 py-2 bg-green-500 text-white rounded hover:bg-green-600 flex items-center justify-center min-w-[200px]"
            >
              <Save size={20} className="mr-2" />
              Save Changes
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  },
);

export default QuestionnaireBuilder;
