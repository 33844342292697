import React, { useState, useEffect, useCallback } from "react";
import { ChevronDown, ChevronUp, Trash2, PlusCircle, Info } from "lucide-react";
import {
  AnswerTypes,
  DifficultyLevels,
  ImportanceLevels,
  QuestionTypes,
} from "./constants";

import { ConditionTypes } from "./constants";
import CustomNumberInput from "./CustomNumberInput";
import { Tooltip } from "react-tooltip";

const ConditionValueInput = ({ conditionType, value, onChange }) => {
  switch (conditionType) {
    case "YES_NO":
    case ConditionTypes.YES_NO:
      return (
        <select
          defaultValue={value}
          onChange={(e) => onChange(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      );

    case "KEYWORD":
    case ConditionTypes.KEYWORD:
      return (
        <div>
          <select
            value={value.matchType}
            onChange={(e) => onChange({ ...value, matchType: e.target.value })}
            className="w-full p-2 border rounded mb-2"
          >
            <option value="matched">Matched</option>
            <option value="not_matched">Not Matched</option>
          </select>
          <input
            type="text"
            value={value.keywords}
            onChange={(e) => onChange({ ...value, keywords: e.target.value })}
            placeholder="Enter keywords (comma-separated)"
            className="w-full p-2 border rounded"
          />
        </div>
      );

    case ConditionTypes.RATING:
    case "RATING":
      return (
        <div>
          <select
            value={value.type}
            onChange={(e) => onChange({ ...value, type: e.target.value })}
            className="w-full p-2 border rounded mb-2"
          >
            <option value="lessThan">Less than</option>
            <option value="moreThan">More than</option>
            <option value="exactly">Exactly</option>
          </select>
          <CustomNumberInput
            value={value.value || 5}
            onChange={(newValue) => onChange({ ...value, value: newValue })}
            min={1}
            max={10}
          />
        </div>
      );

    case "CONFIDENCE":
    case "COMPLETENESS":
    case ConditionTypes.CONFIDENCE:
    case ConditionTypes.COMPLETENESS:
      return (
        <div>
          <input
            type="range"
            min="0"
            max="100"
            value={value}
            onChange={(e) => onChange(parseInt(e.target.value))}
            className="w-full"
          />
          <span>{value}%</span>
        </div>
      );

    case "LENGTH":
    case ConditionTypes.LENGTH:
      return (
        <div>
          <select
            value={value.type}
            onChange={(e) => onChange({ ...value, type: e.target.value })}
            className="w-full p-2 border rounded mb-2"
          >
            <option value="lessThan">Less than</option>
            <option value="moreThan">More than</option>
          </select>
          <CustomNumberInput
            value={value.value || 0}
            onChange={(newValue) => onChange({ ...value, value: newValue })}
            min={0}
          />
        </div>
      );

    default:
      return null;
  }
};
const QuestionCard = ({
  question,
  index,
  expanded,
  expandedQuestions,
  onToggleExpand,
  onUpdate,
  onDelete,
  onAddFollowUp,
  onUpdateFollowUp,
  parentExpanded = true,
  level = 0, // Add level prop to track nesting depth
  dragHandle,
}) => {
  const [criteriaText, setCriteriaText] = useState("");
  const [tagsText, setTagsText] = useState("");
  const [showQualificationTitle, setShowQualificationTitle] = useState(
    question.question_type === "Qualification" ? true : false,
  );
  const [showDocumentationTitle, setShowDocumentationTitle] = useState(
    question.question_type === "Documentation" ? true : false,
  );
  // Recursive function to count all follow-up questions

  const countFollowUpQuestions = (q) => {
    if (!q.follow_up_questions || q.follow_up_questions.length === 0) return 0;
    return q.follow_up_questions.reduce((total, followUp) => {
      return total + 1 + countFollowUpQuestions(followUp.question);
    }, 0);
  };

  const followUpCount = countFollowUpQuestions(question);
  const getDisabledQualificationType = () => {
    switch (question.answer_type.toLowerCase()) {
      case AnswerTypes.IMAGE.toLowerCase():
        return [
          QuestionTypes.ASSESSMENT,
          QuestionTypes.SETTING,
          QuestionTypes.REFERENCE,
        ]; // Options to disable for Option A

      case AnswerTypes.FILE.toLowerCase():
        return [
          QuestionTypes.ASSESSMENT,
          QuestionTypes.PROFILE,
          QuestionTypes.SETTING,
          QuestionTypes.REFERENCE,
        ]; // Options to disable for Option A
      default:
        return [];
    }
  };
  useEffect(() => {
    if (Array.isArray(question.evaluation_criteria)) {
      setCriteriaText(question.evaluation_criteria.join(", "));
    }
    if (Array.isArray(question.tags)) {
      setTagsText(question.tags.join(", "));
    }
  }, [question._id]); // Only run when question id changes

  useEffect(() => {
    setShowQualificationTitle(false);
    setShowDocumentationTitle(false);
    if (question.question_type === "Qualification") {
      setShowQualificationTitle(true);
    } else if (question.question_type === "Documentation") {
      setShowDocumentationTitle(true);
    }
  }, [question.question_type]);
  const getDifficultyColor = (level) => {
    const colors = {
      Easy: "bg-green-100 border-green-500",
      Medium: "bg-yellow-100 border-yellow-500",
      Hard: "bg-red-100 border-red-500",
    };
    return colors[level] || "";
  };

  const getImportanceColor = (level) => {
    const colors = {
      Low: "bg-blue-100 border-blue-300",
      Medium: "bg-blue-200 border-blue-500",
      High: "bg-purple-200 border-purple-500",
      Critical: "bg-red-200 border-red-500",
    };
    return colors[level] || "";
  };

  // Handler for multiple choice option updates
  const handleAddOption = () => {
    const newOption = { option: "", score: 0 };

    // If answer_scoring is not an array, initialize it
    const currentScoring = Array.isArray(question.answer_scoring)
      ? question.answer_scoring
      : [];

    onUpdate("answer_scoring", [...currentScoring, newOption]);
  };

  const renderAnswerTypeFields = () => {
    switch (question.answer_type) {
      case AnswerTypes.YES_NO:
        return (
          <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
            <label className="block text-sm font-medium text-gray-700">
              Yes/No Answer Scoring
            </label>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-xs text-gray-500">Yes Score</label>
                <CustomNumberInput
                  value={question.answer_scoring?.Yes || 1} // Current value
                  onChange={(newValue) => {
                    if (newValue >= 0 && newValue <= 1) {
                      onUpdate("answer_scoring", {
                        ...question.answer_scoring,
                        Yes: parseFloat(newValue.toFixed(2)), // Update 'Yes' score
                      });
                    }
                  }}
                  min={0} // Minimum value
                  max={1} // Maximum value
                />
              </div>
              <div>
                <label className="block text-xs text-gray-500">No Score</label>
                <CustomNumberInput
                  value={question.answer_scoring?.No || 0} // Current value or default
                  onChange={(newValue) => {
                    if (newValue >= 0 && newValue <= 1) {
                      onUpdate("answer_scoring", {
                        ...question.answer_scoring,
                        No: parseFloat(newValue.toFixed(2)), // Update 'No' score
                      });
                    }
                  }}
                  min={0} // Minimum value
                  max={1} // Maximum value
                />
              </div>
            </div>
          </div>
        );

      case AnswerTypes.MULTIPLE_CHOICE:
      case AnswerTypes.MULTIPLE_CHOICE_MULTI_ANSWER:
      case AnswerTypes.RANKED:
      case AnswerTypes.MULTIPLE_CHOICE_OTHER:
        return (
          <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
            {question.answer_type ===
              AnswerTypes.MULTIPLE_CHOICE_MULTI_ANSWER ? (
              <div className="flex w-full gap-2 items-center">
                <div className="flex-auto">
                  <label className="flex items-center gap-2">
                    <Info
                      size={14}
                      className="mr-1"
                      data-tooltip-id="promt_file_upload_tooltip"
                      data-tooltip-content="If turned ON it will ask the candidate to upload file(s) related to the option they have selected"
                    />
                    <Tooltip id="promt_file_upload_tooltip" />
                    <span className="text-sm font-medium text-gray-700">
                      File upload prompt for selected options -
                    </span>
                    <input
                      value="1"
                      type="checkbox"
                      onChange={(e) => {
                        const value = e.target.checked;
                        onUpdate("promt_file_upload", value);
                      }}
                      className="rounded"
                      checked={question?.promt_file_upload ?? false}
                    />
                  </label>
                </div>
                <input
                  type="text"
                  value={
                    question?.promt_file_upload_helper_text ??
                    "Please upload evidence of this by uploading one or more files."
                  }
                  onChange={(e) => {
                    const value =
                      e.target.value ??
                      "Please upload evidence of this by uploading one or more files.";
                    onUpdate("promt_file_upload_helper_text", value);
                  }}
                  className="p-2 border rounded mr-2 flex-auto w-1/2"
                  disabled={!question?.promt_file_upload}
                />
              </div>
            ) : (
              ""
            )}
            <label className="block text-sm font-medium text-gray-700 mb-2">
              {question.answer_type === AnswerTypes.RANKED
                ? "Ranked Options"
                : "Multiple Choice Options"}
            </label>
            {Array.isArray(question.answer_scoring) &&
              question.answer_scoring.map((option, index) => (
                <div key={index} className="flex items-center mb-2">
                  <input
                    type="text"
                    value={option.option}
                    onChange={(e) => {
                      const updatedOptions = [...question.answer_scoring];
                      updatedOptions[index] = {
                        ...option,
                        option: e.target.value,
                      };
                      onUpdate("answer_scoring", updatedOptions);
                    }}
                    className="flex-grow p-2 border rounded mr-2"
                    placeholder={`Option ${index + 1}`}
                  />
                  <CustomNumberInput
                    value={option.score}
                    onChange={(newValue) => {
                      if (newValue >= 0 && newValue <= 1) {
                        const updatedOptions = [...question.answer_scoring];
                        updatedOptions[index] = {
                          ...option,
                          score: parseFloat(newValue.toFixed(2)),
                        };
                        onUpdate("answer_scoring", updatedOptions);
                      }
                    }}
                    min={0}
                    max={1}
                  />
                  <button
                    onClick={() => {
                      if (question.answer_scoring.length > 1) {
                        const updatedOptions = question.answer_scoring.filter(
                          (_, i) => i !== index,
                        );
                        onUpdate("answer_scoring", updatedOptions);
                      }
                    }}
                    className="text-red-500 hover:text-red-700"
                    title="Remove option"
                    disabled={question.answer_scoring.length <= 1}
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              ))}
            <button
              onClick={handleAddOption}
              className="mt-2 px-2 py-1 bg-blue-500 text-white rounded flex items-center"
            >
              <PlusCircle size={16} className="mr-1" /> Add Option
            </button>
          </div>
        );

      case AnswerTypes.NUMERIC:
        return (
          <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
            <label className="block text-sm font-medium text-gray-700">
              Numeric Input Settings
            </label>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label className="block text-xs text-gray-500">
                  Minimum Value
                </label>
                <CustomNumberInput
                  value={question.minValue || 0} // Default to 0 if minValue is undefined
                  onChange={(newValue) => onUpdate("minValue", newValue)} // Directly update minValue
                  min={0} // Define a sensible minimum, adjustable as needed
                  max={1000} // Optional, define an upper limit if applicable
                />
              </div>
              <div>
                <label className="block text-xs text-gray-500">
                  Maximum Value
                </label>
                <CustomNumberInput
                  value={question.maxValue || 0} // Default to 0 if maxValue is undefined
                  onChange={(newValue) => onUpdate("maxValue", newValue)} // Directly update maxValue
                  min={0} // Define the minimum value, adjustable as needed
                  max={1000} // Define an appropriate upper limit
                />
              </div>
              <div>
                <label className="block text-xs text-gray-500">Step</label>
                <CustomNumberInput
                  value={question.step || 1} // Default to 1 if step is undefined
                  onChange={(newValue) => onUpdate("step", newValue)} // Update step directly
                  min={1} // Minimum step value (adjust as needed)
                  max={100} // Optional, define a sensible maximum if applicable
                />
              </div>
            </div>
          </div>
        );

      case AnswerTypes.FILE:
        return (
          <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
            <label className="block text-sm font-medium text-gray-700">
              File Upload Settings
            </label>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-xs text-gray-500">
                  Allowed File Types
                </label>
                <input
                  type="text"
                  value={question.allowedFileTypes || ""}
                  onChange={(e) => onUpdate("allowedFileTypes", e.target.value)}
                  className="w-full p-2 border rounded"
                  placeholder=".pdf,.doc,.jpg"
                />
              </div>
              <div>
                <label className="block text-xs text-gray-500">
                  Max File Size (MB)
                </label>
                <CustomNumberInput
                  value={question.maxFileSize || 0} // Default to 0 if undefined
                  onChange={(newValue) => {
                    onUpdate("maxFileSize", newValue); // Directly update maxFileSize
                  }}
                  min={0} // Minimum file size (e.g., 0 MB)
                  max={100} // Set an appropriate maximum (e.g., 100 MB)
                  step={1}
                />
              </div>
            </div>
          </div>
        );

      case AnswerTypes.DATE:
      case AnswerTypes.DATE_TIME:
        return (
          <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
            <label className="block text-sm font-medium text-gray-700">
              {question.answer_type === AnswerTypes.DATE
                ? "Date Settings"
                : "Date and Time Settings"}
            </label>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-xs text-gray-500">
                  Minimum{" "}
                  {question.answer_type === AnswerTypes.DATE
                    ? "Date"
                    : "Date/Time"}
                </label>
                <input
                  type={
                    question.answer_type === AnswerTypes.DATE
                      ? "date"
                      : "datetime-local"
                  }
                  value={question.minDate || ""}
                  onChange={(e) => onUpdate("minDate", e.target.value)}
                  className="w-full p-2 border rounded"
                />
              </div>
              <div>
                <label className="block text-xs text-gray-500">
                  Maximum{" "}
                  {question.answer_type === AnswerTypes.DATE
                    ? "Date"
                    : "Date/Time"}
                </label>
                <input
                  type={
                    question.answer_type === AnswerTypes.DATE
                      ? "date"
                      : "datetime-local"
                  }
                  value={question.maxDate || ""}
                  onChange={(e) => onUpdate("maxDate", e.target.value)}
                  className="w-full p-2 border rounded"
                />
              </div>
            </div>
          </div>
        );

      case AnswerTypes.EMAIL:
        return (
          <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
            <label className="block text-sm font-medium text-gray-700">
              Email Settings
            </label>
            <div>
              <label className="block text-xs text-gray-500">
                Allowed Domains (Optional)
              </label>
              <input
                type="text"
                value={question.allowedDomains || ""}
                onChange={(e) => onUpdate("allowedDomains", e.target.value)}
                className="w-full p-2 border rounded"
                placeholder="example.com, company.com"
              />
              <p className="text-xs text-gray-500 mt-1">
                Comma-separated list of allowed email domains
              </p>
            </div>
          </div>
        );

      case AnswerTypes.URL:
        return (
          <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
            <label className="block text-sm font-medium text-gray-700">
              URL Settings
            </label>
            <div>
              <label className="block text-xs text-gray-500">
                Allowed Protocols
              </label>
              <input
                type="text"
                value={question.allowedProtocols || ""}
                onChange={(e) => onUpdate("allowedProtocols", e.target.value)}
                className="w-full p-2 border rounded"
                placeholder="http, https"
              />
              <p className="text-xs text-gray-500 mt-1">
                Comma-separated list of allowed protocols
              </p>
            </div>
          </div>
        );

      case AnswerTypes.PHONE:
        return (
          <div className="mb-2 ml-3 bg-yellow-50 p-3 shadow">
            <label className="block text-sm font-medium text-gray-700">
              Phone Number Settings
            </label>
            <div>
              <label className="block text-xs text-gray-500">
                Format Pattern
              </label>
              <input
                type="text"
                value={question.phoneFormat || ""}
                onChange={(e) => onUpdate("phoneFormat", e.target.value)}
                className="w-full p-2 border rounded"
                placeholder="XXX-XXX-XXXX"
              />
              <p className="text-xs text-gray-500 mt-1">
                Use X for digits, specify desired format
              </p>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  if (!parentExpanded) return null;

  const handleFollowUpClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    onAddFollowUp(question._id);
  };

  return (
    <div className={`mb-4 ${index % 2 === 0 ? "bg-gray-100" : "bg-white"}`}>
      <div
        className={`rounded-lg shadow-md p-4 ${level === 0 ? "bg-white" : "bg-yellow-50"}`}
      >
        <div className="flex items-center mb-2">
          <h3
            className="text-lg font-semibold flex-grow cursor-pointer w-3/4"
            onClick={() => onToggleExpand(question._id)}
          >
            {!expanded && question.question_text}
          </h3>
          <div className="flex items-center gap-2">
            <button
              onClick={() => onToggleExpand(question._id)}
              className="px-2 py-1 bg-blue-500 text-white rounded flex items-center"
              title={expanded ? "Collapse" : "Expand"}
            >
              {expanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
            </button>

            <button
              onClick={handleFollowUpClick}
              className="px-2 py-1 bg-green-500 text-white rounded flex items-center group relative"
              title="Add follow-up question"
            >
              <PlusCircle size={16} className="mr-1" />
              <span>Follow-up</span>
              {followUpCount > 0 && (
                <span className="ml-1 bg-white text-green-600 text-xs font-medium px-2 py-0.5 rounded-full">
                  {followUpCount}
                </span>
              )}
            </button>

            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(question._id);
              }}
              className="p-2 text-red-500 hover:text-red-700 transition-colors rounded hover:bg-red-50"
              title="Remove question"
            >
              <Trash2 size={20} />
            </button>
          </div>
        </div>

        {expanded && (
          <div className="space-y-4">
            <textarea
              value={question.question_text}
              onChange={(e) => onUpdate("question_text", e.target.value)}
              placeholder="Enter question text"
              className="w-full p-2 border rounded mb-2 resize-y"
              rows="3"
            />

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Job Role
                </label>
                <input
                  type="text"
                  value={question.job_role || ""}
                  onChange={(e) => onUpdate("job_role", e.target.value)}
                  className="w-full p-2 border rounded"
                  placeholder="Enter job role"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Category
                </label>
                <input
                  type="text"
                  value={question.category || ""}
                  onChange={(e) => onUpdate("category", e.target.value)}
                  className="w-full p-2 border rounded"
                  placeholder="Enter category"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Difficulty Level
                </label>
                <select
                  value={question.difficulty_level}
                  onChange={(e) => onUpdate("difficulty_level", e.target.value)}
                  className={`w-full p-2 border rounded ${getDifficultyColor(question.difficulty_level)}`}
                >
                  {Object.values(DifficultyLevels).map((level) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Importance
                </label>
                <select
                  value={question.importance}
                  onChange={(e) => onUpdate("importance", e.target.value)}
                  className={`w-full p-2 border rounded ${getImportanceColor(question.importance)}`}
                >
                  {Object.values(ImportanceLevels).map((level) => (
                    <option key={level} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Answer Type
                </label>
                <select
                  value={question.answer_type}
                  onChange={(e) => onUpdate("answer_type", e.target.value)}
                  className="w-full p-2 border rounded"
                >
                  {Object.values(AnswerTypes).map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Question Type
                </label>
                <select
                  value={question.question_type}
                  onChange={(e) => onUpdate("question_type", e.target.value)}
                  className="w-full p-2 border rounded"
                >
                  {Object.values(QuestionTypes).map((type) => (
                    <option
                      key={type}
                      value={type}
                      disabled={getDisabledQualificationType().includes(type)}
                    >
                      {type}
                    </option>
                  ))}
                </select>
                {showQualificationTitle ? (
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mt-5">
                      Qualification Title
                    </label>
                    <input
                      type="text"
                      value={question.qualification_title || ""}
                      onChange={(e) =>
                        onUpdate("qualification_title", e.target.value)
                      }
                      className="w-full p-2 border rounded "
                      placeholder="Enter qualfication title"
                    />
                  </div>
                ) : (
                  ""
                )}
                {showDocumentationTitle ? (
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mt-5">
                      Document Title
                    </label>
                    <input
                      type="text"
                      value={question.document_title || ""}
                      onChange={(e) =>
                        onUpdate("document_title", e.target.value)
                      }
                      className="w-full p-2 border rounded "
                      placeholder="Enter document title"
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            {renderAnswerTypeFields()}

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Evaluation Criteria
                </label>
                <textarea
                  value={criteriaText}
                  onChange={(e) => {
                    const newText = e.target.value;
                    setCriteriaText(newText);
                    // Update the actual criteria array on blur or when needed
                    const criteria = newText
                      .split(",")
                      .map((item) => item.trim())
                      .filter(Boolean);
                    onUpdate("evaluation_criteria", criteria);
                  }}
                  className="w-full p-2 border rounded resize-y"
                  rows="2"
                  placeholder="Enter evaluation criteria (comma-separated)"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Context
                </label>
                <textarea
                  value={question.context || ""}
                  onChange={(e) => onUpdate("context", e.target.value)}
                  className="w-full p-2 border rounded resize-y"
                  rows="2"
                  placeholder="Enter question context"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Company Explanation
                </label>
                <textarea
                  value={question.company_explanation || ""}
                  onChange={(e) =>
                    onUpdate("company_explanation", e.target.value)
                  }
                  className="w-full p-2 border rounded resize-y"
                  rows="2"
                  placeholder="Enter company explanation"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Tags
                </label>
                <input
                  type="text"
                  value={tagsText}
                  onChange={(e) => {
                    const newText = e.target.value;
                    setTagsText(newText);
                    // Update the actual tags array on change
                    const tags = newText
                      .split(",")
                      .map((item) => item.trim())
                      .filter(Boolean);
                    onUpdate("tags", tags);
                  }}
                  className="w-full p-2 border rounded"
                  placeholder="Enter tags (comma-separated)"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Model Answer
                </label>
                <textarea
                  value={question.model_answer || ""}
                  onChange={(e) => onUpdate("model_answer", e.target.value)}
                  className="w-full p-2 border rounded resize-y"
                  rows="4"
                  placeholder="Enter model answer"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Checklist
                </label>
                <input
                  type="text"
                  value={question?.checklist?.name || ""}
                  onChange={(e) =>
                    onUpdate("checklist", { name: e.target.value })
                  }
                  className="w-full p-2 border rounded"
                  placeholder="Enter checklist name"
                />
              </div>
            </div>
            {/* Follow-up Questions Section */}
            {/* Follow-up Questions Section */}
            {question.follow_up_questions &&
              question.follow_up_questions.map((followUp, followUpIndex) => (
                <div key={followUp.id} className="mt-4 ml-8 relative">
                  <div className="absolute -left-6 top-1/2 transform -translate-y-1/2 w-6 h-px bg-gray-300"></div>

                  <div className="p-4 bg-yellow-50 rounded-lg shadow">
                    <h4 className="font-semibold mb-2">
                      Follow-up Question {followUpIndex + 1}
                    </h4>

                    {/* Condition controls */}
                    <div className="grid grid-cols-2 gap-4 mb-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Condition Type
                        </label>
                        <select
                          defaultValue={followUp.condition}
                          onChange={(e) =>
                            onUpdateFollowUp(
                              question._id,
                              followUp.id,
                              "condition",
                              e.target.value,
                            )
                          }
                          className="w-full p-2 border rounded"
                        >
                          {Object.entries(ConditionTypes).map(
                            ([key, value]) => (
                              <option key={key} value={key}>
                                {value}
                              </option>
                            ),
                          )}
                        </select>
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Condition Value
                        </label>
                        <ConditionValueInput
                          conditionType={followUp.condition}
                          value={followUp.condition_value}
                          onChange={(value) =>
                            onUpdateFollowUp(
                              question._id,
                              followUp.id,
                              "condition_value",
                              value,
                            )
                          }
                        />
                      </div>
                    </div>

                    {/* Nested QuestionCard */}
                    <QuestionCard
                      question={followUp.question}
                      index={index}
                      expanded={expandedQuestions.includes(
                        followUp.question._id,
                      )}
                      expandedQuestions={expandedQuestions}
                      onToggleExpand={onToggleExpand}
                      onUpdate={(field, value) =>
                        onUpdateFollowUp(
                          question._id,
                          followUp.id,
                          "question",
                          { ...followUp.question, [field]: value },
                        )
                      }
                      onDelete={onDelete}
                      onAddFollowUp={onAddFollowUp}
                      onUpdateFollowUp={onUpdateFollowUp}
                      parentExpanded={true}
                      level={level + 1}
                    />
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionCard;
