import React, { useState, useEffect } from "react";
import { ChevronDown, ChevronUp, Plus } from "lucide-react";
import { api } from "../utils/api";

const FAQItem = ({ question, answer, isOpen, toggleOpen }) => {
  return (
    <div className="border-b border-gray-200">
      <button
        className="flex w-full justify-between items-center py-4 px-4 text-left"
        onClick={toggleOpen}
      >
        <span className="font-medium text-gray-900">{question}</span>
        {isOpen ? (
          <ChevronUp className="h-5 w-5 text-gray-500" />
        ) : (
          <ChevronDown className="h-5 w-5 text-gray-500" />
        )}
      </button>
      {isOpen && (
        <div className="py-4 px-4 bg-gray-50">
          <p className="text-gray-600">{answer}</p>
        </div>
      )}
    </div>
  );
};

const FAQAccordion = ({ faqs = [] }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleQuestion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="w-full max-w-2xl mx-auto bg-white rounded-lg">
      <div className="p-5 border-b border-gray-200 mt-2">
        <h4 className="font-bold text-gray-500">
          These faqs will use to deliver info via whatsapp chat to jobseekers
        </h4>
      </div>
      <div className="divide-y divide-gray-200">
        {faqs.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            isOpen={openIndex === index}
            toggleOpen={() => toggleQuestion(index)}
          />
        ))}
        <div className="text-center p-10">
          {faqs.length === 0 ? "No faqs found !" : ""}
        </div>
      </div>
    </div>
  );
};

const AddFAQForm = ({ onAddFAQ }) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate inputs
    if (!question.trim() || !answer.trim()) {
      setError("Both question and answer are required");
      return;
    }

    const faq = await api.post("/api/faq", { question, answer });

    // Call the parent function to add the FAQ
    onAddFAQ({ question, answer });

    // Reset form fields and error
    setQuestion("");
    setAnswer("");
    setError("");
  };

  return (
    <div className="w-full max-w-2xl mx-auto bg-white rounded-lg">
      <div className="p-5 border-b border-gray-200">
        <h4 className="text-xl font-bold text-gray-500">Add New FAQ</h4>
      </div>
      <form onSubmit={handleSubmit} className="p-5 space-y-4">
        {error && (
          <div className="p-3 bg-red-50 border border-red-200 text-red-600 rounded">
            {error}
          </div>
        )}
        <div>
          <label
            htmlFor="question"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Question
          </label>
          <input
            type="text"
            id="question"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            className="mt-1 block w-full rounded-lg border border-gray-200 bg-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm px-4 py-3 hover:bg-white focus:bg-white transition-all duration-200 ease-in-out resize-none"
            placeholder="Enter FAQ question"
          />
        </div>
        <div>
          <label
            htmlFor="answer"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Answer
          </label>
          <textarea
            id="answer"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            rows="4"
            className="mt-1 block w-full rounded-lg border border-gray-200 bg-gray-100 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm px-4 py-3 hover:bg-white focus:bg-white transition-all duration-200 ease-in-out resize-none"
            placeholder="Enter FAQ answer"
          ></textarea>
        </div>
        <button
          type="submit"
          className="inline-flex bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition duration-300 ease-in-out transform hover:scale-105"
        >
          Add FAQ
        </button>
      </form>
    </div>
  );
};

const FAQManager = () => {
  const [faqs, setFaqs] = useState([]);
  useEffect(() => {
    const getFaq = async () => {
      const list = await api.get("/api/faq");
      setFaqs(list.data);
    };
    getFaq();
  }, []);

  const handleAddFAQ = (newFAQ) => {
    setFaqs([...faqs, newFAQ]);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 bg-white p-6 rounded-lg">
      <FAQAccordion faqs={faqs} />
      <AddFAQForm onAddFAQ={handleAddFAQ} />
    </div>
  );
};

export default FAQManager;
